import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import XLSX from "xlsx";
import { PATHS } from "../../Constants/Paths";
import { eventsBulkHeaderFields } from "../../Helpers/EventsHelper";
import { userBulkHeaderFields } from "../../Helpers/UsersHelper";
import {
  resetEventsBulkHeaderFields,
  setEventsBulkColumnFieldMap,
  setEventsBulkData,
  setEventsBulkHasHeaders,
} from "../../Redux/ClientEvents/actions";
import {
  resetUserBulkHeaderFields,
  setGroupColumn,
  setUserBulkColumnFieldMap,
  setUserBulkData,
  setUserBulkFormattedData,
  setUserBulkHasHeaders,
  setUsersColumnsSelected,
} from "../../Redux/ClientUsers/actions";
import { addToastToStack } from "../../Redux/Toast/actions";
import "./Dropzone.css";

const Dropzone = ({ openDropzone, show, setOpenDropzone }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isReading, setIsReading] = useState(false);
  const onDrop = useCallback((acceptedFiles, fileRejection) => {
    setIsReading(true);
    if (fileRejection.length > 0) {
      setIsReading(false);
      dispatch(
        addToastToStack(
          "danger",
          "Please select a valid file, only .xls, .xlsx and .csv files are supported."
        )
      );
    }

    acceptedFiles.forEach((file) => {
      setIsReading(false);
      const reader = new FileReader();

      reader.onabort = () => console.error("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const data = processFile(binaryStr);

        if (location.pathname === PATHS.usersModule) {
          dispatch(setUserBulkFormattedData(null))
          dispatch(setUserBulkData(null));
          dispatch(setUsersColumnsSelected(false));
          dispatch(setGroupColumn(""));
          dispatch(setUserBulkColumnFieldMap(null));
          dispatch(setUserBulkHasHeaders(false));
          dispatch(setUserBulkData(data));
          dispatch(resetUserBulkHeaderFields(userBulkHeaderFields));
        } else if (location.pathname === PATHS.eventsModule) {
          dispatch(setEventsBulkHasHeaders(false));
          dispatch(setEventsBulkData(data));
          dispatch(setEventsBulkColumnFieldMap(null));
          dispatch(resetEventsBulkHeaderFields(eventsBulkHeaderFields));
        }
      };

      reader.readAsBinaryString(file);
    });
  }, []);

  useEffect(() => {
    if (openDropzone) {
      open();
      setOpenDropzone(false);
    }
  }, [openDropzone]);

  const processFile = (file) => {
    const wb = XLSX.read(file, { type: "binary" });
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, {
      blankrows: false,
      header: 1,
      defval: null,
    });
    return data;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept:
      "text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop,
  });

  return (
    <div className={`dropzone ${!show ? "d-none" : ""}`} {...getRootProps()}>
      {
        <div className="d-flex flex-column">
          {isReading ? (
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <h4 className="">Drag 'n' drop some files here</h4>
          )}
          <input {...getInputProps()} />
        </div>
      }
    </div>
  );
};

export default Dropzone;
