import { createSelector } from 'reselect'
import { get } from 'lodash'
import { INIT_STATE } from './reducer'

const key = 'clientUsers'

const selectClientUsers = state => get(state, key, INIT_STATE)

const selectBulkColumnFieldMap = createSelector(
  selectClientUsers, state => get(state, 'bulkColumnFieldMap', {})
)

const selectBulkFormattedColumns = createSelector(
  selectClientUsers, state => get(state, 'bulkFormattedColumns', {})
)

const selectBulkFormattedData = createSelector(
  selectClientUsers, state => get(state, 'bulkFormattedData', {})
)

const selectUsersColumnsSelected = createSelector(
  selectClientUsers, state => get(state, 'usersColumnsSelected', {})
)

const selectUsers = createSelector(
  selectClientUsers, state => get(state, 'users', {})
)

const selectUsersBulkData = createSelector(
  selectClientUsers, state => get(state, 'bulkData', {})
)

const selectUsersBulkDataSelected = createSelector(
  selectClientUsers, state => get(state, 'usersBulkDataSelected', {})
)

const selectUserKvps = createSelector(
  selectClientUsers, state => get(state, 'userKvps', {})
)

const selectUserGroups = createSelector(
  selectClientUsers, state => get(state, 'userGroups', {})
)

const selectUserBeingPushed = createSelector(
  selectClientUsers, state => get(state, 'userBeingPushed', {})
)

const selectGroupColumn = createSelector(
  selectClientUsers, state => get(state, 'groupColumn', {})
)

const selectBulkHasHeaders = createSelector(
  selectClientUsers, state => get(state, 'bulkHasHeaders', {})
)

const selectUserProfile = createSelector(
  selectClientUsers, state => get(state, 'userProfile', {})
)

const selectUserProfileToUpdate = createSelector(
  selectClientUsers, state => get(state, 'userProfileToUpdate', {})
)

const selectIsKvpActive = createSelector(
  selectClientUsers, state => get(state, 'isKvpActive', {})
)

const selectIsAddingKvp = createSelector(
  selectClientUsers, state => get(state, 'isAddingKvp', {})
)

const selectNewKvpKey = createSelector(
  selectClientUsers, state => get(state, 'newKvpKey', {})
)

const selectNewKvpValue = createSelector(
  selectClientUsers, state => get(state, 'newKvpValue', {})
)

const selectUserSelected = createSelector(
  selectClientUsers, state => get(state, 'userSelected', {})
)

const selectGroupColumnOriginalData = createSelector(
  selectClientUsers, state => get(state, 'groupColumnOriginalData', {})
)

const selectNewProfileImageFile = createSelector(
  selectClientUsers, state => get(state, 'newProfileImageFile', {})
)

const selectIsFetchingUserProfile = createSelector(
  selectClientUsers, state => get(state, 'isFetchingProfile', {})
)

const selectIsFetchingUserProfileImage = createSelector(
  selectClientUsers, state => get(state, 'isFetchingProfileImage', {})
)

const selectIsUserProfileEdited = createSelector(
  selectClientUsers, state => get(state, 'isUserProfileEdited', {})
)

export default {
    BULKCOLUMNFIELDMAP: selectBulkColumnFieldMap,
    BULKFORMATTEDCOLUMNS: selectBulkFormattedColumns,
    USERSCOLUMNSSELECTED: selectUsersColumnsSelected,
    USERS: selectUsers,
    BULKFORMATTEDDATA: selectBulkFormattedData,
    USERSBULKDATA: selectUsersBulkData,
    USERSBULKDATASELECTED: selectUsersBulkDataSelected,
    USERKVPS: selectUserKvps,
    USERGROUPS: selectUserGroups,
    USERBEINGPUSHED: selectUserBeingPushed,
    GROUPCOLUMN: selectGroupColumn,
    BULKHASHEADERS: selectBulkHasHeaders,
    USERPROFILE: selectUserProfile,
    USERPROFILETOUPDATE: selectUserProfileToUpdate,
    ISKVPACTIVE: selectIsKvpActive,
    ISADDINGKVP: selectIsAddingKvp,
    NEWKVPKEY: selectNewKvpKey,
    NEWKVPVALUE: selectNewKvpValue,
    USERSELECTED: selectUserSelected,
    GROUPCOLUMNORIGINALDATA: selectGroupColumnOriginalData,
    NEWPROFILEIMAGEFILE: selectNewProfileImageFile,
    ISFETCHINGPROFILE: selectIsFetchingUserProfile,
    ISFETCHINGPROFILEIMAGE: selectIsFetchingUserProfileImage,
    ISUSERPROFILEEDITED: selectIsUserProfileEdited,
  }