import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientCategories,
  getClientEvents,
  setEventSelected
} from "../../../../../Redux/ClientEvents/actions";

const EventsGrid = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const clientEvents = useSelector((state) => state.clientEvents.events);
  const clientCategories = useSelector(
    (state) => state.clientEvents.categories
  );
  const userClientId = useSelector((state) => state.user.userClientId);
  const clientIdRequested = useSelector(
    (state) => state.clients.clientIdRequested
  );
  const dispatch = useDispatch();

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    suppressSizeToFit: true,
    flex: 1,
    // floatingFilter: true,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Select field...",
      field: "select",
      disabled: false,
      // default: true,
    },
    {
      headerName: "Name",
      field: "name",
      disabled: false,
      type: "event",
    },
    {
      headerName: "Duration",
      field: "duration",
      disabled: false,
      type: "video",
    },
    {
      headerName: "Start Time",
      field: "startTime",
      disabled: false,
      type: "event",
    },
    {
      headerName: "Event Categories",
      field: "categories",
      disabled: false,
      type: "eventCategory",
    },
    {
      headerName: "Player Page",
      field: "eventFeaturesCustomPlayerPageOverrideSnippetId",
      disabled: false,
      type: "event",
    },
    {
      headerName: "Q&A",
      field: "eventFeaturesQuestionAndAnswer",
      disabled: false,
      type: "event",
    },
    {
      headerName: "Voting",
      field: "eventFeaturesVoting",
      disabled: false,
      type: "video",
    },
  ]);

  useEffect(() => {
    
      dispatch(getClientEvents(userClientId));
    
  }, [userClientId]);

  useEffect(() => {
    if (!clientCategories) {
      dispatch(getClientCategories());
    }
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows && 1 === selectedRows.length) {
      dispatch(setEventSelected(selectedRows[0]));
    }
  };

  return (
    <div className="UsersGrid">
      <div className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={clientEvents}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default EventsGrid;
