import { combineReducers } from 'redux';
import auth from './Auth/reducer';
import user from './User/reducer';
import toast from './Toast/reducer';
import clientUsers from './ClientUsers/reducer';
import clientEvents from './ClientEvents/reducer';
import tabs from './Tabs/reducer';
import clients from './Clients/reducer';

const reducer = combineReducers({
    auth,
    user,
    toast,
    clientUsers,
    clientEvents,
    tabs,
    clients,
});

export default reducer;
