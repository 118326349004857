import authService from '../../Services/Auth.service';
import { removeUserCredentials, setUserCredentials } from '../User/actions';
import {
  SET_AUTHENTICATION,
  SET_AUTHENTICATING,
  SET_AUTHENTICATE_ERROR,
  CLEAR_AUTHENTICATE_ERRORS,
  CLEAR_AUTH_DATA
} from '../actions';
import { removeUserState } from '../ClientUsers/actions';
import { removeClientState } from '../Clients/actions';

export const authenticate = (email, password, domain) => {
  return (dispatch) => {
    dispatch(setAuthenticating(true))

    return new Promise ((resolve, reject) => {
      authService.signIn(email, password, domain)
        .then((data) => {
          dispatch(setUserCredentials(data))
          dispatch(setAuthentication(true))
          dispatch(setAuthenticating(false))
          resolve(true)
        })
        .catch((err) => {
          if(err.status === 401){
            dispatch(setAuthenticateError('Please check your credentials.'))
          }
          if(err.status === 409){
            dispatch(setAuthenticateError(err.data))
          }
          dispatch(setAuthenticating(false))
          reject(false)
        })
    })
  }
};

export const refreshToken = () => {
  return (dispatch) => {
    
    return new Promise ((resolve, reject) => {
      authService.refreshToken()
        .then((data) => {
          dispatch(setUserCredentials(data))
          dispatch(setAuthentication(true))
          resolve(true)
        })
        .catch((err) => {
          dispatch(setAuthentication(false))
          dispatch(removeUserCredentials())
          reject(false)
        })
    })
  }
};

export const logout = () => {
  return (dispatch) => {
    return new Promise ((resolve) => {
      authService.logoutUser()
        .then((res) => {
          dispatch(removeClientState())
          dispatch(removeUserState())
          dispatch(setAuthentication(false))
          dispatch(clearUserData())
          resolve(true)
        })
    })
  }
};

export const setAuthenticating = (isAuthenticating) => {
  return {
    type: SET_AUTHENTICATING,
    payload: isAuthenticating,
  };
};

export const setAuthentication = (isAuthenticated) => {
  return {
    type: SET_AUTHENTICATION,
    payload: isAuthenticated,
  };
};

export const setAuthenticateError = (msg) => {
  return {
    type: SET_AUTHENTICATE_ERROR,
    errorMsg: msg
  };
};

export const clearAuthenticateErrors = () => {
  return {
    type: CLEAR_AUTHENTICATE_ERRORS,
  };
};

export const clearUserData = () => {
  return {
    type: CLEAR_AUTH_DATA,
  };
};