import { MDBBtn, MDBIcon } from "mdbreact";
import React from "react";
import { useHistory } from "react-router-dom";
import { ENVIRONMENTS } from "../../Constants/Environments";
import { PATHS } from "../../Constants/Paths";
import environment from "../../environments/environment";
import "./Dashboard.css";

const { APP_ENV } = environment;
const { REACT_APP_VERSION } = process.env;

const Dashboard = () => {
  const iconSize = "7x";
  const history = useHistory();

  const goToUserManagement = () => {
    history.push(PATHS.usersModule);
  };

  const goToEventManagement = () => {
    history.push(PATHS.eventsModule);
  };

  return (
    <div className="Dashboard">
      <MDBBtn
        outline
        color="secondary"
        type="button"
        className="dash-button"
        onClick={goToUserManagement}
      >
        <MDBIcon icon="user-alt" size={iconSize} />
        <span>User Management</span>
      </MDBBtn>
      {ENVIRONMENTS.DEV === APP_ENV && (
        <MDBBtn
          outline
          color="secondary"
          type="button"
          className="dash-button"
          onClick={goToEventManagement}
        >
          <MDBIcon icon="calendar-alt" size={iconSize} />
          <span>Event Management</span>
        </MDBBtn>
      )}
      <div className="version-container">
        <span className="version">v{REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

export default Dashboard;
