import {
    SET_ACTIVE_TAB,
} from '../actions';
    
  const INIT_STATE = {
    activeTab: 1
  };
    
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            }
              
        default:
            return { 
            ...state 
            };
    }
  };
    
  export default reducer;