import _ from "lodash";
import {getName} from 'country-list';


export const userBulkHeaderFields = [
  {
    label: "Select field...",
    value: "select",
    disabled: false,
    // default: true,
  },
  {
    label: "First name",
    value: "firstName",
    disabled: false,
    type: 'account',
  },
  {
    label: "Surname",
    value: "surname",
    disabled: false,
    type: 'account',
  },
  {
    label: "Email",
    value: "email",
    disabled: false,
    type: 'account',
  },
  {
    label: "Password",
    value: "password",
    disabled: false,
    type: 'account',
  },
  {
    label: "Groups",
    value: "groups",
    disabled: false,
    type: 'groups',
  },
  {
    label: "Bio",
    value: "bio",
    disabled: false,
    type: 'profile',
  },
  {
    label: "City",
    value: "city",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Contact calendar link",
    value: "contactCalendarLink",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Contact info company",
    value: "contactInfoCompany",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Contact info email",
    value: "contactInfoEmail",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Contact info phone",
    value: "contactInfoPhone",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Contact info title",
    value: "contactInfoTitle",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Country",
    value: "country",
    disabled: false,
    type: 'profile',
  },
  {
    label: "custom data 1",
    value: "customData1",
    disabled: false,
    type: 'profile',
  },
  {
    label: "custom data 2",
    value: "customData2",
    disabled: false,
    type: 'profile',
  },
  {
    label: "custom data 3",
    value: "customData3",
    disabled: false,
    type: 'profile',
  },
  {
    label: "custom data 4",
    value: "customData4",
    disabled: false,
    type: 'profile',
  },
  {
    label: "custom data 5",
    value: "customData5",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Profile privacy",
    value: "hideProfilePrivacyOptOut",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Social Facebook",
    value: "socialFacebook",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Social Instagram",
    value: "socialInstagram",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Social LinkedIn",
    value: "socialLinkedIn",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Social Twitter",
    value: "socialTwitter",
    disabled: false,
    type: 'profile',
  },
  {
    label: "Social Youtube",
    value: "socialYoutube",
    disabled: false,
    type: 'profile',
  },
];

export const mapBulkDataToGrid = (data, hasHeaders) => {
  let result = null;

  if (data && 0 < data.length) {
    result = {
      columns: [],
      data: []
    };

    const dataHeaders = data[0];
    const rows = !hasHeaders ? data : data.filter((el, i) => i !== 0);

    dataHeaders.forEach((header, i) => {
      const newColumn = {
        headerName: hasHeaders ? header : `Column ${i + 1}`,
        field: `column${i + 1}`,
        headerComponentParams: { show: true },
        cellEditor: 'customCellEditor',
      }
      result.columns.push(newColumn);
    });

    result.data = rows.map((row, idx) => {
      let object = { idx };
      row.forEach((field, i) => {
        object[`column${i + 1}`] = field
      });
      return object;
    })
  }

  return result;
};

export const buildBulkData = (seletedRows, columnFieldMap, bulkHeaderFields) => {
  let bulkUsers = null;
  if (bulkHeaderFields) {
    bulkUsers = [];

    const columns = Object.keys(columnFieldMap);
    const columnsWithTypes = columns.map((col) => {
      let column;
      bulkHeaderFields.forEach((config) => {
        if (columnFieldMap[col] === config.value) {
          column = {
            value: config.value,
            agColumn: col,
            type: config.type
          }
        } else if (columnFieldMap[col] === 'userId') {
          column = {
            value: 'userId',
            agColumn: 'userId',
            type: 'userId',
          }
        }
      })
      return column
    })

    bulkUsers = seletedRows.map((row) => {
      let newUser = {
        rowId: row.rowId
      }
      columnsWithTypes.forEach((col) => {
        let data = {
          ...col,
          data: row.data[col.agColumn],
        }
        newUser[col.value] = data
      })
      return newUser
    })

  } else {
    if (seletedRows && 0 < seletedRows.length && columnFieldMap) {
      bulkUsers = [];

      const columns = Object.keys(columnFieldMap);

      seletedRows.forEach(row => {
        const newUser = {};

        columns.forEach(column => {
          const field = columnFieldMap[column];
          const rowColumnValue = row[column];
          newUser[field] = rowColumnValue;
        });

        bulkUsers.push(newUser);
      });
    }
  }

  return bulkUsers;
}

export const validateCell = (field, value) => {
  if(field === 'firstName' || field === 'surname'){
    if(value === null || value === undefined){
      value = ""
    }
    if(value.length < 2 || value.trim().length < 2){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must have at least 2 characters.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };
  
  if(field === 'bio' || field === 'customData1' || field === 'customData2' || field === 'customData3' || field === 'customData4' || field === 'customData5'){
    if(value === null || value === undefined){
      value = ""
    }
    if( value !== '' && value.length > 1024){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must have 1024 characters at most.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };

  if(field === 'city' || field === 'contactInfoPhone' || field === 'contactInfoTitle'){
    if(value === null || value === undefined){
      value = ""
    }
    if(value !== '' && value.length > 255){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must have 255 characters at most.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };

  if(field === 'contactCalendarLink' || field === 'contactInfoCompany' || field === 'socialFacebook' || field === 'socialInstagram' || field === 'socialLinkedIn' || field === 'socialTwitter' || field === 'socialYoutube'){
    if(value === null || value === undefined){
      value = ""
    }
    if(value !== '' && !_.startsWith(value, 'https://')){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must start with 'https://'.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };

  if(field === 'contactInfoEmail'){
    if(value === null || value === undefined){
      value = ""
    }
    if( value !== '' && !value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must be a valid email.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };

  if(field === 'email'){
    if(value === null || value === undefined){
      value = ""
    }
    if(!value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must be a valid email.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };  

  if(field === 'hideProfilePrivacyOptOut'){
    if(value === null || value === undefined){
      value = ""
    }
    if(value !== '' && !(value === 'true' || value === 'false' || value === true || value === false)){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must be true or false.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  };  

  if(field === 'country'){
    if(value === null || value === undefined){
      value = ""
    }
    if(value !== "" && !getName(value)){
      return {
        isValid: false,
        msg: `${_.capitalize(field)} must be a 2 characters ISO code.`
      }
    } else{
      return {
        isValid: true,
        msg: ''
      }
    }
  }; 


  if(field === 'password'){
    try {
      if(value === null || value === undefined){
        value = ""
      }
      if(!value.match(/(?=^.{6,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/)){
        return {
          isValid: false,
          msg: `${_.capitalize(field)} must have at least 6 characters and contain 1 uppercase, 1 lowercase, 1 number and 1 symbol.`
        }
      } else{
        return {
          isValid: true,
          msg: ''
        }
      }
    } catch (error) {
      return {
        isValid: false,
        msg: 'Unable to validate password.'
      }
    }
  };  
};

export const updateProfileData = (completeProfile, newData) => {
  let newProfile = {}

  for (const key in completeProfile) {
    if(newData[key]){
      newProfile[key] = newData[key]
    } else {
      newProfile[key] = completeProfile[key]
    }
  }

  return newProfile;
} 