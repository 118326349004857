import {
  SET_CLIENT_USERS,
  SET_FETCHING_USERS,
  SET_FETCHING_USER_PROFILE,
  SET_USER_BULK_COLUMN_FIELD_MAP,
  SET_USER_BULK_DATA,
  SET_USER_BULK_FORMATTED_COLUMNS,
  SET_USER_BULK_FORMATTED_DATA,
  SET_USER_BULK_HAS_HEADERS,
  SET_USER_BULK_HEADER_FIELDS,
  SET_USER_BULK_USERS,
  SET_USER_PROFILE,
  SET_USER_PROFILE_IMAGE,
  SET_USER_SELECTED,
  SET_USERS_BULK_COLUMN_FIELD_SELECT,
  SET_USERS_COLUMNS_SELECTED,
  SET_USERS_BULK_DATA_SELECTED,
  REMOVE_USERS_STATE,
  SET_USER_KVPS,
  SET_USERS_GROUPS,
  SET_USER_BEING_PUSHED,
  SET_IS_GROUP_COLUMN,
  SET_USER_PROFILE_TO_UPDATE,
  SET_KVP_ACTIVE,
  SET_IS_ADDING_KVP,
  SET_NEW_KVP_KEY,
  SET_NEW_KVP_VALUE,
  SET_GROUP_COLUMN_ORIGINAL_DATA,
  SET_NEW_PROFILE_IMAGE_FILE,
  SET_FETCHING_USER_PROFILE_IMAGE,
  SET_IS_USER_PROFILE_EDITED
} from '../actions';

export const INIT_STATE = {
  users: null,
  isFetchingUsers: false,
  userSelected: null,
  isFetchingProfile: false,
  isFetchingProfileImage: false,
  userProfile: null,
  userProfileToUpdate: null,
  userProfileImage: null,
  userKvps: [],
  bulkData: null,
  bulkHasHeaders: null,
  bulkHeaderFields: null,
  bulkFormattedColumns: null,
  bulkFormattedData: null,
  bulkColumnFieldMap: null,
  bulkUsers: null,
  usersBulkColumnFieldSelect: null,
  usersColumnsSelected: false,
  usersBulkDataSelected: null,
  userGroups: [],
  userBeingPushed: null,
  groupColumn: false,
  isKvpActive: false,
  isAddingKvp: false,
  newKvpKey: "",
  newKvpValue: "",
  groupColumnOriginalData: [],
  newProfileImageFile: null,
  isUserProfileEdited: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CLIENT_USERS:
      return {
        ...state,
        users: action.payload
      };

    case SET_FETCHING_USERS:
      return {
        ...state,
        isFetchingUsers: action.payload
      };

    case SET_USER_SELECTED:
      return {
        ...state,
        userSelected: action.payload
      };

    case SET_FETCHING_USER_PROFILE:
      return {
        ...state,
        isFetchingProfile: action.payload
      };

    case SET_FETCHING_USER_PROFILE_IMAGE:
      return {
        ...state,
        isFetchingProfileImage: action.payload
      };

    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };

    case SET_USER_PROFILE_TO_UPDATE:
      return {
        ...state,
        userProfileToUpdate: action.payload
      };

    case SET_USER_PROFILE_IMAGE:
      return {
        ...state,
        userProfileImage: action.payload
      };

    case SET_USER_KVPS:
      return {
        ...state,
        userKvps: action.payload
      };

    case SET_USER_BULK_DATA:
      return {
        ...state,
        bulkData: action.payload
      };

    case SET_USER_BULK_HAS_HEADERS:
      return {
        ...state,
        bulkHasHeaders: action.payload
      };

    case SET_USER_BULK_HEADER_FIELDS:
      return {
        ...state,
        bulkHeaderFields: action.payload
      };

    case SET_USER_BULK_FORMATTED_COLUMNS:
      return {
        ...state,
        bulkFormattedColumns: action.payload
      };

    case SET_USER_BULK_FORMATTED_DATA:
      return {
        ...state,
        bulkFormattedData: action.payload
      };

    case SET_USER_BULK_COLUMN_FIELD_MAP:
      return   {
        ...state,
        bulkColumnFieldMap: action.payload
      }

    case SET_USER_BULK_USERS:
      return {
        ...state,
        bulkUsers: action.payload
      };

    case SET_USERS_BULK_COLUMN_FIELD_SELECT:
      return {
        ...state,
        usersBulkColumnFieldSelect: action.payload
      };

    case SET_USERS_COLUMNS_SELECTED:
      return {
        ...state,
        usersColumnsSelected: action.payload
      }
    
    case SET_USERS_BULK_DATA_SELECTED:
      return {
        ...state,
        usersBulkDataSelected: action.payload
      }
  
    case SET_USERS_GROUPS:
      return {
        ...state,
        userGroups: action.payload
      }

    case SET_USER_BEING_PUSHED:
      return {
        ...state,
        userBeingPushed: action.payload
      }

    case SET_IS_GROUP_COLUMN:
      return {
        ...state,
        groupColumn:  action.payload,
      }
    
    case SET_KVP_ACTIVE:
      return {
        ...state,
        isKvpActive:  action.payload,
      }
    
    case SET_IS_ADDING_KVP:
      return {
        ...state,
        isAddingKvp:  action.payload,
      }
    
    case SET_NEW_KVP_KEY:
      return {
        ...state,
        newKvpKey:  action.payload,
      }
    
    case SET_NEW_KVP_VALUE:
      return {
        ...state,
        newKvpValue:  action.payload,
      }
    
    case SET_GROUP_COLUMN_ORIGINAL_DATA:
      return {
        ...state,
        groupColumnOriginalData: action.payload
      }
    
    case SET_NEW_PROFILE_IMAGE_FILE:
      return {
        ...state,
        newProfileImageFile: action.payload
      }
    
    case SET_IS_USER_PROFILE_EDITED:
      return {
        ...state,
        isUserProfileEdited: action.payload
      }

    case REMOVE_USERS_STATE:
      return {
        ...state,
        users: null,
        isFetchingUsers: false,
        userSelected: null,
        isFetchingProfile: false,
        isFetchingProfileImage: false,
        userProfile: null,
        userProfileToUpdate: null,
        userProfileImage: null,
        userKvps: null,
        bulkData: null,
        bulkHasHeaders: null,
        bulkHeaderFields: null,
        bulkFormattedColumns: null,
        bulkFormattedData: null,
        bulkColumnFieldMap: null,
        bulkUsers: null,
        usersBulkColumnFieldSelect: null,
        usersColumnsSelected: false,
        usersBulkDataSelected: null,
        userGroups: [],
        userBeingPushed: null,
        groupColumn: false,
        isKvpActive: false,
        isAddingKvp: false, 
        newKvpKey: "",
        newKvpValue: "",
        groupColumnOriginalData: [],
        newProfileImageFile: null,
        isUserProfileEdited: false,
      }

      default:
      return {
        ...state
      };
  }
};

export default reducer;