import axios from "axios";
import environment from "../environments/environment";
import storageService from "./Storage.service";

const { APP_API_URL } = environment;
const KEY_ACCESS_TOKEN = "access_token";
const KEY_DOMAIN = "domain";
const EVENTS_PATHNAME = "/Events";
const CATEGORIES_PATHNAME = "/Categories";

export class EventService {
  getEvents(client) {
    const url = `${APP_API_URL}${EVENTS_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);
    const domainStorage = storageService.get(KEY_DOMAIN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Client-Override": client,
        "X-InfernoCore-Domain": domainStorage,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  getCategories() {
    const url = `${APP_API_URL}${CATEGORIES_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }
}

const eventService = new EventService();
export default eventService;
