import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import CLIENTUSERSELECTORS from '../../../../../../Redux/ClientUsers/selectors';
import './CustomGroupCell.css';
import { addUserToGroup, removeUserFromGroup, setUserBulkFormattedData } from '../../../../../../Redux/ClientUsers/actions';

const CustomGroupCell = (props) => {
    const [selected, setSelected] = useState([]);
    const [prevSelected, setPrevSelected] = useState([]);
    const [editedSuccess, setEditedSuccess] = useState(false);
    const [editedFails, setEditedFails] = useState(false);
    const dispatch = useDispatch();
    const userGroups = useSelector(CLIENTUSERSELECTORS.USERGROUPS);
    const groupColumn = useSelector(CLIENTUSERSELECTORS.GROUPCOLUMN);
    const bulkFormattedData = useSelector(CLIENTUSERSELECTORS.BULKFORMATTEDDATA);
    const selectRef = useRef();
    const selectContainer = useRef();

    useEffect(() => {
        let selectedOptions = [];
        if(props.indivGrid){
            props.data.groups.forEach((group) => {
                userGroups.forEach((option) => {
                    if(option.value === group){
                        selectedOptions.push(option)
                    }
                })
            })
        } else {
            if(groupColumn){
                let value = props.value
                let groupsData

                if(value === null || value === undefined || value.length === 0){
                    value = ""
                    groupsData = value.split(',')
                } else if( typeof value === 'string'){
                    groupsData = value.split(',')
                } else {
                    groupsData = value
                    setSelected(groupsData)
                    setPrevSelected(groupsData)
                    bulkFormattedData[props.rowIndex][props.colDef.field] = groupsData
                    dispatch(setUserBulkFormattedData(bulkFormattedData))
                    return
                }     

                let nodeData = props.node.data
                let column = props.colDef.field
                userGroups.forEach((group) => {
                    groupsData.forEach((groupData) => {
                        if (group.label.toLowerCase() === groupData.trim() || group.value.toLowerCase() === groupData.trim() || group.label === groupData.trim() || group.value === groupData.trim()) {
                            group.originalValue = groupData
                            selectedOptions.push(group)
                        }
                    })
                })
                nodeData[column] = selectedOptions
            }
        }
        setSelected(selectedOptions)
        setPrevSelected(selectedOptions)
    }, [])


    const handleChange = async (value) => {
        await setSelected(value)
        let nodeData = props.node.data
        let column = props.colDef.field
        
        nodeData[column] = value

        let removeAll = false
        if(value.length === 0){
            removeAll = true
        }

        props.api.forEachNode(function (rowNode) {
            if(rowNode.id === props.node.id){
                if(!removeAll){
                    if(selectContainer.current.clientHeight > 45){
                        rowNode.setRowHeight(selectContainer.current.clientHeight); 
                    }
                } else {
                    rowNode.setRowHeight(45); 
                }
            }
        });
        props.api.onRowHeightChanged()
        if(!props.indivGrid){
            props.node.setSelected(false)
        }
    }
    
    const handleSelection = (e) => {
        if(props.indivGrid) {
            props.node.setSelected(true)
            props.api.clearFocusedCell()
        }
        props.api.forEachNode(function (rowNode) {
            if(rowNode.id === props.node.id){
                    if(selectContainer.current.clientHeight > 45){
                        rowNode.setRowHeight(selectContainer.current.clientHeight); 
                    }
            }
        });
        props.api.onRowHeightChanged()
    }

    const handleIndivUpdate = async(e) => {
        if(props.indivGrid){
            let editSucess = true;
            const selectedArr = selected.map((opt) => {
                return opt.value
            })
            const prevSelectedArr = prevSelected.map((opt) => {
                return opt.value
            })
            if(_.isEqual(selectedArr, prevSelectedArr)){
                return
            }
            const groupsToAdd = _.difference(selectedArr, prevSelectedArr)
            const groupsToRemove = _.difference(prevSelectedArr, selectedArr)

            if(groupsToAdd.length > 0){
                await dispatch(addUserToGroup(props.data.id, groupsToAdd))
                    .then((res) => {
                        editSucess=true
                    })
                    .catch((err) => {
                        editSucess=false
                    })
            }

            if(groupsToRemove.length > 0){
                await dispatch(removeUserFromGroup(props.data.id, groupsToRemove))
                    .then((res) => {
                        editSucess=true
                    })
                    .catch((err) => {
                        editSucess=false
                    })
            }

            if(editSucess){
                setEditedSuccess(true)
                setTimeout(() => {
                    setEditedSuccess(false)
                }, 2000)
            } else {
                setEditedFails(true)
                setTimeout(() => {
                    setEditedFails(false)
                }, 2000)
                setSelected(prevSelected)
            }
            setPrevSelected(selected)
        }
    }

    return (
        <div ref={selectContainer} onBlur={(e) => handleIndivUpdate(e)}>
            <Select
                ref={selectRef}
                isMulti
                className={`ag-grid-react-multiselect-container ${editedSuccess ? 'success-animation' : ''} ${editedFails ? 'fails-animation' : ''} `}
                classNamePrefix="ag-grid-react-multiselect"
                options={userGroups}
                value={selected}
                onChange={(value) => handleChange(value)}
                menuPortalTarget={document.body}
                menuShouldBlockScroll
                menuPlacement='auto'
                onFocus={(value) => handleSelection(value)}
                openMenuOnClick={false}
            />
        </div>
    );
};

export default CustomGroupCell;