/*** AUTH ACTIONS ***/
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const SET_AUTHENTICATING = 'SET_AUTHENTICATING';
export const SET_AUTHENTICATE_ERROR = 'SET_AUTHENTICATE_ERROR';
export const CLEAR_AUTHENTICATE_ERRORS = 'CLEAR_AUTHENTICATE_ERRORS';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';

/*** USER ACTIONS ***/
export const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_CLIENTID = 'SET_USER_CLIENTID';
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_USER_CREDENTIALS = 'REMOVE_USER_CREDENTIALS';

/*** TOAST ACTIONS ***/
export const SHOW_SUCCESS_TOAST = 'SHOW_SUCCESS_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const ADD_TOAST = 'ADD_TOAST';

/*** CLIENT USERS ACTIONS ***/
export const SET_CLIENT_USERS = 'SET_CLIENT_USERS';
export const SET_FETCHING_USERS = 'SET_FETCHING_USERS';
export const SET_USER_SELECTED = 'SET_USER_SELECTED';
export const SET_FETCHING_USER_PROFILE = 'SET_FETCHING_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_PROFILE_TO_UPDATE = 'SET_USER_PROFILE_TO_UPDATE';
export const SET_USER_PROFILE_IMAGE = 'SET_USER_PROFILE_IMAGE';
export const SET_USER_KVPS = 'SET_USER_KVPS';
export const SET_USER_BULK_DATA = 'SET_USER_BULK_DATA';
export const SET_USER_BULK_HAS_HEADERS = 'SET_USER_BULK_HAS_HEADERS';
export const SET_USER_BULK_HEADER_FIELDS = 'SET_USER_BULK_HEADER_FIELDS';
export const SET_USER_BULK_FORMATTED_COLUMNS = 'SET_USER_BULK_FORMATTED_COLUMNS';
export const SET_USER_BULK_FORMATTED_DATA = 'SET_USER_BULK_FORMATTED_DATA';
export const SET_USER_BULK_COLUMN_FIELD_MAP = 'SET_USER_BULK_COLUMN_FIELD_MAP';
export const SET_USER_BULK_USERS = 'SET_USER_BULK_USERS';
export const SET_USERS_BULK_COLUMN_FIELD_SELECT = 'SET_USERS_BULK_COLUMN_FIELD_SELECT';
export const SET_USERS_COLUMNS_SELECTED = 'SET_USERS_COLUMNS_SELECTED';
export const SET_USERS_BULK_DATA_SELECTED = 'SET_USERS_BULK_DATA_SELECTED';
export const REMOVE_USERS_STATE = 'REMOVE_USERS_STATE';
export const SET_USERS_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_BEING_PUSHED = 'SET_USER_BEING_PUSHED';
export const SET_IS_GROUP_COLUMN = 'SET_IS_GROUP_COLUMN';
export const SET_KVP_ACTIVE = 'SET_KVP_ACTIVE';
export const SET_IS_ADDING_KVP = 'SET_IS_ADDING_KVP'
export const SET_NEW_KVP_KEY = 'SET_NEW_KVP_KEY';
export const SET_NEW_KVP_VALUE = 'SET_NEW_KVP_VALUE';
export const SET_GROUP_COLUMN_ORIGINAL_DATA = 'SET_GROUP_COLUMN_ORIGINAL_DATA';
export const UPDATE_USER_PROFILE_IMAGE = 'UPDATE_USER_PROFILE_IMAGE';
export const SET_NEW_PROFILE_IMAGE_FILE = 'SET_NEW_PROFILE_IMAGE_FILE';
export const SET_FETCHING_USER_PROFILE_IMAGE = 'SET_FETCHING_USER_PROFILE_IMAGE';
export const SET_IS_USER_PROFILE_EDITED = 'SET_IS_USER_PROFILE_EDITED';

/*** CLIENT EVENTS ACTIONS ***/
export const SET_CLIENT_EVENTS = 'SET_CLIENT_EVENTS';
export const SET_FETCHING_EVENTS = 'SET_FETCHING_EVENTS';
export const SET_EVENT_SELECTED = 'SET_EVENT_SELECTED';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FETCHING_CATEGORIES = 'SET_FETCHING_CATEGORIES';
export const SET_CLIENT_ID_REQUESTED = 'SET_CLIENT_ID_REQUESTED';
export const SET_EVENT_BULK_DATA = 'SET_EVENT_BULK_DATA';
export const SET_EVENT_BULK_COLUMN_FIELD_SELECT = 'SET_EVENT_BULK_COLUMN_FIELD_SELECT';
export const SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED = 'SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED';
export const SET_EVENT_BULK_HAS_HEADERS = 'SET_EVENT_BULK_HAS_HEADERS';
export const SET_EVENT_BULK_HEADER_FIELDS = 'SET_EVENT_BULK_HEADER_FIELDS';
export const SET_EVENT_BULK_FORMATTED_COLUMNS = 'SET_EVENT_BULK_FORMATTED_COLUMNS';
export const SET_EVENT_BULK_FORMATTED_DATA = 'SET_EVENT_BULK_FORMATTED_DATA';
export const SET_EVENT_BULK_FORMATTED_DATA_SELECTED = 'SET_EVENT_BULK_FORMATTED_DATA_SELECTED';
export const SET_EVENT_BULK_DATA_SELECTION_VALID = 'SET_EVENT_BULK_DATA_SELECTION_VALID';
export const SET_EVENT_BULK_COLUMN_FIELD_MAP = 'SET_EVENT_BULK_COLUMN_FIELD_MAP';

/*** TABS ACTIONS ***/
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

/*** CLIENT ACTIONS ***/
export const SET_USER_CLIENTS = 'SET_USER_CLIENTS';
export const REMOVE_CLIENT_STATE = 'REMOVE_CLIENT_STATE';