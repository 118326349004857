import { authRoles } from "./auth/authRoles";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import EventsModule from "./Components/Modules/EventsModule/EventsModule";
import UsersModule from "./Components/Modules/UsersModule/UsersModule";
import { PATHS } from './Constants/Paths';

export const Routes = [
    {
        path: PATHS.login,
        component: Login
    },
    {
        path: PATHS.dashboard,
        component: Dashboard,
        auth: authRoles.admin
    },
    {
        path: PATHS.usersModule,
        component: UsersModule,
        auth: authRoles.admin
    },
    {
        path: PATHS.eventsModule,
        component: EventsModule,
        auth: authRoles.admin
    }
]
