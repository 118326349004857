import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addingKvp,
  getClientUsers,
  getGroups,
  getUserKvps,
  getUserProfile,
  getUserProfileImage,
  setKvpActive,
  setNewProfileImageFile,
  setUserProfile,
  setUserProfileImage,
  setUserSelected
} from "../../../../../Redux/ClientUsers/actions";
import CLIENTUSERSSELECTOR from "../../../../../Redux/ClientUsers/selectors";
import USERSELECTOR from '../../../../../Redux/User/selectors';
import CLIENTSELECTOR from '../../../../../Redux/Clients/selector';
import CustomGroupCell from "../../UserBulkUpdate/UserBulkUpdateGrid/CustomGroupCell/CustomGroupCell";
import CustomCellEditor from "../CustomCellEditor/CustomCellEditor";
import "./UsersGrid.css";
import NoRowsOverlay from "../../../../Overlay/NoRowsOverlay";
import { addToastToStack } from "../../../../../Redux/Toast/actions";

const UsersGrid = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2000);
  const [alertShowed, setAlertShowed] = useState(false);
  const clientUsers = useSelector(CLIENTUSERSSELECTOR.USERS);
  const userClientid = useSelector(USERSELECTOR.USERCLIENTID);
  const newProfileImageFile = useSelector(CLIENTUSERSSELECTOR.NEWPROFILEIMAGEFILE);
  const isUserProfileEdited = useSelector(CLIENTUSERSSELECTOR.ISUSERPROFILEEDITED);
  const userSelected = useSelector(CLIENTUSERSSELECTOR.USERSELECTED);
  const previousSelected = useSelector(CLIENTSELECTOR.PREVIUOSSELECTED);
  const dispatch = useDispatch();

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    // floatingFilter: true,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "User Id",
      field: "id",
      suppressSizeToFit: true,
      flex: 1,
    },
    {
      headerName: "First Name",
      field: "firstName",
      suppressSizeToFit: true,
      flex: .6,
      editable: false,
      enableCellChangeFlash: false,
      cellRenderer: 'customCellEditor',
    },
    {
      headerName: "Last Name",
      field: "surname",
      suppressSizeToFit: true,
      flex: .6,
      editable: false,
      enableCellChangeFlash: false,
      cellRenderer: 'customCellEditor',
    },
    {
      headerName: "Email",
      field: "email",
      flex: .8,
      suppressSizeToFit: true,
    },
    {
      headerName: "Groups",
      field: "groups",
      suppressSizeToFit: true,
      flex: 1,
      cellRenderer: "customGroupCell",
      editable: false,
      cellRendererParams: {indivGrid: true},
    },
  ]);

  useEffect(() => {
    if(userClientid !== previousSelected || !clientUsers){
      dispatch(getClientUsers(pageNumber, pageSize, userClientid));
      dispatch(getGroups())  
    }
  }, [pageNumber, pageSize, userClientid]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onSelectionChanged = () => {
    if((newProfileImageFile || isUserProfileEdited) && !alertShowed){
      dispatch(addToastToStack('warning', 'Please update the profile or cancel the edit before changing the row.'))
      setAlertShowed(true)
      userSelected.setSelected(true)
      setTimeout(() => {
        setAlertShowed(false)
      }, [4000])
      return
    } else if(newProfileImageFile || isUserProfileEdited) {
      userSelected.setSelected(true)
      return
    }

    setAlertShowed(false)
    const selectedNodes = gridApi.getSelectedNodes();
    if (selectedNodes && 1 === selectedNodes.length) {
      const userId = selectedNodes[0].data.id;
      dispatch(setUserProfile(null));
      dispatch(setUserProfileImage(null));
      dispatch(setNewProfileImageFile(null));
      dispatch(setUserSelected(selectedNodes[0]));
      dispatch(setKvpActive(false))
      dispatch(addingKvp(false))
      dispatch(getUserProfile(userId));
      dispatch(getUserProfileImage(userId));
      dispatch(getUserKvps(userId));
    }
  };


  return (
    <div className="UsersGrid">
      <div className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={clientUsers}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
          frameworkComponents={{ 
            customGroupCell: CustomGroupCell,
            customCellEditor: CustomCellEditor,
            customNoRowsOverlay: NoRowsOverlay,
          }}
          noRowsOverlayComponent={'customNoRowsOverlay'}
          noRowsOverlayComponentParams={{
            loadingMessage: "Loading rows ...",
          }}
          rowBuffer={30}
          //debounceVerticalScrollbar
          pagination
          paginationPageSize={50}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default UsersGrid;
