import React from 'react';
import { MDBContainer } from 'mdbreact';
import Toast from './Toast';
import { useSelector } from 'react-redux';

const ToastContainer = () => {

    const toasts = useSelector(state => state.toast)

    return(
        <MDBContainer 
            style={{
                position: "fixed",
                top: "90px",
                right: "5px",
                zIndex: 9999,
                maxWidth: 'max-content'
            }}
        >
            { toasts.stack &&
                toasts.stack.map((toast, index) => <Toast key={index} toast={toast}/>)
            }
        </MDBContainer>
    )
}

export default ToastContainer;