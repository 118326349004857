import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PATHS } from "../Constants/Paths";
import { logout } from "../Redux/Auth/actions";
import { Routes } from "../Routes";

const haveAccess = (location, user, routes) => {
  const { pathname } = location;
  const matched = routes.find((r) => r.path === pathname);
  try {
    if (matched) {
      if (matched.auth) {
        let haveAccess = false;
        user.userRoles.forEach((role) => {
          const matchRoles = matched.auth.find((e) => e === role);
          if (matchRoles) {
            haveAccess = true;
          }
        });
        if (haveAccess) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
};

const AuthGuard = ({ children }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const routes = Routes;

  useEffect(() => {
    const checkAccess = haveAccess(location, user, Routes);
    if (!checkAccess) {
      dispatch(logout()).then((res) => {
        history.push(PATHS.login);
      });
    }
  }, [user, location, routes]);

  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
