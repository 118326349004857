import { createSelector } from 'reselect'
import { get } from 'lodash'
import { INIT_STATE } from './reducer'

const key = 'user'

const selectUser = state => get(state, key, INIT_STATE)

const selectUserClientId = createSelector(
    selectUser, state => get(state, 'userClientId', {})
  )

export default {
    USERCLIENTID: selectUserClientId,
  }