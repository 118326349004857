import {
  SET_AUTHENTICATION,
  SET_AUTHENTICATING,
  SET_AUTHENTICATE_ERROR,
  CLEAR_AUTHENTICATE_ERRORS,
  CLEAR_AUTH_DATA
} from '../actions';
  
const INIT_STATE = {
  isAuthenticated: false,
  isAuthenticating: false,
  authenticateError: {
    msg: '',
  },
};
  
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return { 
          ...state, 
          isAuthenticated: action.payload 
      };
    case SET_AUTHENTICATING:
      return { 
          ...state, 
          isAuthenticating: action.payload 
      };
    case SET_AUTHENTICATE_ERROR:
      return { 
          ...state, 
          authenticateError: {
            msg: action.errorMsg
          }
      };
    case CLEAR_AUTHENTICATE_ERRORS:
      return { 
          ...state, 
          authenticateError: {
            msg: ''
        }
      };

    case CLEAR_AUTH_DATA:
      return {
        ...state,
        isAuthenticated: false,
        authenticateError: {
          msg: ''
        }
      }
    
    default:
      return { 
        ...state 
      };
  }
};
  
export default reducer;