import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buildBulkData, mapBulkDataToGrid, validateCell
} from "../../../../../Helpers/UsersHelper";
import {
  getClientUsers,
  getGroups,
  removeGroupsColumn,
  removeSelectionColumn,
  setGroupsColumn,
  setSelectionColumn,
  setUserBulkData,
  setUserBulkFormattedColumns,
  setUserBulkFormattedData,
  setUserBulkHasHeaders,
  setUsersBulkDataSelected
} from "../../../../../Redux/ClientUsers/actions";
import CLIENTUSERSSELECTOR from "../../../../../Redux/ClientUsers/selectors";
import USERSELECTOR from '../../../../../Redux/User/selectors';
import CLIENTSELECTOR from '../../../../../Redux/Clients/selector';
import CustomLoadingOverlay from "../../../../Overlay/CustomLoadingOverlay";
import BulkCellsEditor from "./CustomBulkCellEditor/CustomBulkCellEditor";
import CustomColumnHeader from "./CustomColumnHeader/CustomColumnHeader";
import CustomGroupCell from "./CustomGroupCell/CustomGroupCell";
import CustomLoadingCell from "./CustomLoadingCell/CustomLoadingCell";
import "./UserBulkUpdateGrid.css";

const UserBulkUpdateGrid = () => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(2000);
  const [isData, setIsData] = useState(false);
  const bulkFormattedColumns = useSelector(CLIENTUSERSSELECTOR.BULKFORMATTEDCOLUMNS);
  const bulkColumnFieldMap = useSelector(CLIENTUSERSSELECTOR.BULKCOLUMNFIELDMAP);
  const usersColumnsSelected = useSelector(CLIENTUSERSSELECTOR.USERSCOLUMNSSELECTED);
  const bulkData = useSelector(CLIENTUSERSSELECTOR.USERSBULKDATA);
  const bulkFormattedData = useSelector(CLIENTUSERSSELECTOR.BULKFORMATTEDDATA);
  const clientUsers = useSelector(CLIENTUSERSSELECTOR.USERS);
  const groupColumn = useSelector(CLIENTUSERSSELECTOR.GROUPCOLUMN);
  const userClientid = useSelector(USERSELECTOR.USERCLIENTID);
  const previousSelected = useSelector(CLIENTSELECTOR.PREVIUOSSELECTED);

  const bulkHeaderFields = useSelector(
    (state) => state.clientUsers.bulkHeaderFields
  );
  const bulkHasHeaders = useSelector(
    (state) => state.clientUsers.bulkHasHeaders
  );
  const dispatch = useDispatch();

  const cellClassRules  = {
    'validation-error': function(params) {
      if(params.context.usersColumnsSelected){
        const column = params.colDef.field
        const field = params.context.bulkColumnFieldMap[column]
        const validation = validateCell(field, params.value)
        if((validation && validation.isValid) || (field === 'userId' || column === 'selection' || field === 'groups')){
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
  };  

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    editable: true,
    suppressSizeToFit: false,
    cellClassRules: cellClassRules
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if(bulkColumnFieldMap){
      params.api.gridOptionsWrapper.gridOptions.context.bulkColumnFieldMap = bulkColumnFieldMap
    }
    if(usersColumnsSelected){
      params.api.gridOptionsWrapper.gridOptions.context.usersColumnsSelected = true
    }
    params.api.refreshCells()
    setIsData(true)
  };

  const handleCheckChange = () => {
    if(!bulkHasHeaders){
      saveEditedData()
    }
    dispatch(setUserBulkHasHeaders(!bulkHasHeaders));
  };

  const saveEditedData = () => {
    let newData = bulkFormattedData.map((data) => {
      delete data.userId
      delete data.idx
      const values = Object.values(data)
      return values
    })
    if(bulkHasHeaders){
      newData.unshift(bulkData[0])
    }
    dispatch(setUserBulkData(newData))
  }

  const buildUsers = () => {
    const selectedNodes = gridApi?.getSelectedNodes();
    const selectedRows = selectedNodes.map((node) => {
      return {
        rowId: node.id,
        data: node.data,
      };
    });
    const users = buildBulkData(
      selectedRows,
      bulkColumnFieldMap,
      bulkHeaderFields
    );
    return users;
  };

  const onSelectionChanged = () => {
    let users = buildUsers();
    if (users.length === 0) {
      users = null;
    }
    dispatch(setUsersBulkDataSelected(users));
  };

  useEffect(() => {
    return () => {
      if(isData){
        saveEditedData()
      }
    }
  }, [isData])

  useEffect(() => {
    if(userClientid !== previousSelected){
      dispatch(getClientUsers(pageNumber, pageSize, userClientid))
        .then((users) => {
          if(gridApi){
            let emailColumn;
            for (const key in bulkColumnFieldMap) {
              if(bulkColumnFieldMap[key] === 'email'){
                emailColumn = key
              }
            }
            gridApi.forEachNode((node) => {
              users.forEach((user) => {
                if (user.email === node.data[emailColumn]) {
                  node.data.userId = user.id;
                } else {
                  node.data.userId = ''
                }
                node.setData(node.data)
              })
              console.log(node)
            })
          };
        });
      dispatch(getGroups()) 
    }
  }, [pageNumber, pageSize, userClientid]);

  useEffect(() => {
    if (bulkData) {
      const formattedData = mapBulkDataToGrid(bulkData, bulkHasHeaders);
      dispatch(setUserBulkFormattedColumns(formattedData.columns));

      if (bulkFormattedData && usersColumnsSelected) {
        // Asign the new data the usersId if they have before
        let data = buildBulkData(formattedData.data, bulkColumnFieldMap);
        let userId = "";
        const dataWithUserId = formattedData.data.map((row, i) => {
          clientUsers.forEach((user) => {
            if (user.email === data[i].email) {
              userId = user.id;
              row.userId = userId;
            }
          });
          return row;
        });
        dispatch(setUserBulkFormattedData(dataWithUserId));
      } else {
        dispatch(setUserBulkFormattedData(formattedData.data));
      }

      if (usersColumnsSelected) {
        dispatch(setSelectionColumn());
      } else {
        dispatch(removeSelectionColumn());
      }

      if (groupColumn) {
        dispatch(setGroupsColumn(groupColumn));
      } else {
        dispatch(removeGroupsColumn());
      }
    }
  }, [bulkData, bulkHasHeaders]);

  return (
    <div className="UserBulkUpdateGrid">
      <div className="header-checkbox">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitches"
            checked={bulkHasHeaders}
            onChange={handleCheckChange}
            disabled={!bulkFormattedColumns || !bulkFormattedData}
          />
          <label className="custom-control-label" htmlFor="customSwitches">
            Headers
          </label>
        </div>
      </div>
      <div className="ag-theme-alpine ag-user-grid">
        <AgGridReact
          onGridReady={onGridReady}
          suppressMenuHide={true}
          frameworkComponents={{
            agColumnHeader: CustomColumnHeader,
            customLoadingOverlay: CustomLoadingOverlay,
            customLoadingCell: CustomLoadingCell,
            customGroupCell: CustomGroupCell,
            customCellEditor: BulkCellsEditor,
          }}
          loadingOverlayComponent={"customLoadingOverlay"}
          loadingOverlayComponentParams={{
            loadingMessage: "Please select the columns.",
          }}
          defaultColDef={defaultColDef}
          columnDefs={bulkFormattedColumns}
          rowData={bulkFormattedData}
          onSelectionChanged={() => onSelectionChanged()}
          rowSelection="multiple"
          suppressRowClickSelection
          suppressCellSelection={usersColumnsSelected ? false : true}
          applyColumnDefOrder
          context= {{
            usersColumnsSelected: false,
            bulkColumnFieldMap: [],
          }}
          headerHeight={75}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default UserBulkUpdateGrid;
