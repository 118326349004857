import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventsBulkHeaderFields } from "../../../../../Helpers/EventsHelper";
import { mapBulkDataToGrid } from "../../../../../Helpers/UsersHelper";
import {
  setEventsBulkColumnReqFieldsSelected,
  setEventsBulkFormattedColumns,
  setEventsBulkFormattedData,
  setEventsBulkFormattedDataSelected,
  setEventsBulkHasHeaders,
} from "../../../../../Redux/ClientEvents/actions";
import CustomLoadingOverlay from "../../../../Overlay/CustomLoadingOverlay";
import CustomColumnEventsHeader from "./CustomColumnHeader/CustomColumnEventsHeader";
import "./EventsBulkUpdateGrid.css";

const EventBulkUpdateGrid = () => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const bulkColumnFieldMap = useSelector(
    (state) => state.clientEvents.bulkColumnFieldMap
  );
  const bulkColumnFieldSelect = useSelector(
    (state) => state.clientEvents.bulkColumnFieldSelect
  );
  const bulkColumnReqFieldsSelected = useSelector(
    (state) => state.clientEvents.bulkColumnReqFieldsSelected
  );
  const bulkHasHeaders = useSelector(
    (state) => state.clientEvents.bulkHasHeaders
  );
  const bulkFormattedColumns = useSelector(
    (state) => state.clientEvents.bulkFormattedColumns
  );
  const bulkFormattedData = useSelector(
    (state) => state.clientEvents.bulkFormattedData
  );
  const bulkEvents = useSelector((state) => state.clientEvents.bulkEvents);

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    editable: true,
    suppressSizeToFit: true,
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // params.api.sizeColumnsToFit();

    setTimeout(() => {
      params.api.showLoadingOverlay();
    }, 100);
  };

  const handleCheckChange = () => {
    dispatch(setEventsBulkHasHeaders(!bulkHasHeaders));
  };

  // const buildEvents = () => {
  //   const selectedRows = gridApi?.getSelectedRows();
  //   const events = buildBulkData(selectedRows, bulkColumnFieldMap);
  // };

  const onSelectionChanged = () => {
    const selectedRows = gridApi?.getSelectedRows();
    dispatch(setEventsBulkFormattedDataSelected(selectedRows));
  };

  const buildColumns = (formattedData, reqFieldsSelected) => {
    const fColumns = [];

    if (reqFieldsSelected) {
      fColumns.push({
        headerName: "",
        field: "selection",
        width: 100,
        // headerCheckboxSelection: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressSizeToFit: false,
      });
    }

    fColumns.push(...formattedData.columns);

    return fColumns;
  };

  const allRequiredFieldsSelected = (selectedFields) => {
    const requiredFieldsNames = eventsBulkHeaderFields
      .filter((headerField) => headerField.required)
      .map((headerField) => headerField.value);

    const selectedFieldsNames = selectedFields
      ? Object.values(selectedFields)
      : null;

    let result = !requiredFieldsNames || 0 === requiredFieldsNames.length;

    if (!result && selectedFieldsNames) {
      result = true;

      for (const requiredFieldName of requiredFieldsNames) {
        const includesField = selectedFieldsNames.includes(requiredFieldName);
        if (!includesField) {
          result = false;
          break;
        }
      }
    }

    dispatch(setEventsBulkColumnReqFieldsSelected(result));
    return result;
  };

  useEffect(() => {
    if (bulkEvents) {
      const reqFieldsSelected = allRequiredFieldsSelected(bulkColumnFieldMap);
      const formattedData = mapBulkDataToGrid(bulkEvents, bulkHasHeaders);
      const formattedColumns = buildColumns(formattedData, reqFieldsSelected);
      dispatch(setEventsBulkFormattedColumns(formattedColumns));
      dispatch(setEventsBulkFormattedData(formattedData.data));
      dispatch(setEventsBulkFormattedDataSelected(null));

      if (reqFieldsSelected) {
        setTimeout(() => {
          gridApi?.hideOverlay();
        }, 100);
      } else {
        setTimeout(() => {
          gridApi?.showLoadingOverlay();
        }, 100);
      }
    }
  }, [bulkEvents, bulkHasHeaders]);

  useEffect(() => {
    // buildEvents();v
    const reqFieldsSelected = allRequiredFieldsSelected(bulkColumnFieldMap);
    const formattedData = mapBulkDataToGrid(bulkEvents, bulkHasHeaders);
    const formattedColumns = buildColumns(formattedData, reqFieldsSelected);
    dispatch(setEventsBulkFormattedColumns(formattedColumns));

    if (reqFieldsSelected) {
      setTimeout(() => {
        gridApi?.hideOverlay();
      }, 100);
    } else {
      setTimeout(() => {
        gridApi?.showLoadingOverlay();
      }, 100);
    }
  }, [bulkColumnFieldSelect]);

  return (
    <div className="EventsBulkUpdateGrid">
      <div className="header-checkbox">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitches"
            checked={bulkHasHeaders}
            onChange={handleCheckChange}
            disabled={!bulkFormattedColumns || !bulkFormattedData}
          />
          <label className="custom-control-label" htmlFor="customSwitches">
            Headers
          </label>
        </div>
      </div>
      <div className="ag-theme-alpine ag-event-grid">
        <AgGridReact
          suppressMenuHide={true}
          frameworkComponents={{
            agColumnHeader: CustomColumnEventsHeader,
            customLoadingOverlay: CustomLoadingOverlay,
          }}
          loadingOverlayComponent={"customLoadingOverlay"}
          loadingOverlayComponentParams={{
            loadingMessage: "Please select the columns.",
          }}
          noRowsOverlayComponent={"customLoadingOverlay"}
          noRowsOverlayComponentParams={{
            loadingMessage: "Please Import File",
          }}
          defaultColDef={defaultColDef}
          columnDefs={bulkFormattedColumns}
          rowData={bulkFormattedData}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          suppressCellSelection={bulkColumnReqFieldsSelected}
          applyColumnDefOrder
          headerHeight={75}
          onSelectionChanged={onSelectionChanged}
          onGridReady={onGridReady}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default EventBulkUpdateGrid;
