import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CLIENTUSERSELECTORS from '../../../../../../Redux/ClientUsers/selectors';
import './CustomLoadingCell.css';

const CustomLoadingCell = (props) => {
    const [isSelected, setIsSelected] = useState(false)
    const [pushing, setPushing] = useState(false)
    const userBeingPushed = useSelector(CLIENTUSERSELECTORS.USERBEINGPUSHED);
    const usersBulkDataSelected = useSelector(CLIENTUSERSELECTORS.USERSBULKDATASELECTED);

    const selectRow = (e) => {
        props.node.setSelected(!isSelected)
        setIsSelected(!isSelected) 
    }

    useEffect(() => {
        if(userBeingPushed === props.node.id){
            setPushing(true)
            setIsSelected(false)
            props.node.setSelected(false)
        } else {
            setPushing(false)
        }
    }, [userBeingPushed])

    useEffect(() => {
        const selectedNodes = props.api.getSelectedNodes()
        if(selectedNodes.length === 0){
            setIsSelected(false)
        } else {
            selectedNodes.forEach((node) => {
                if(node.id === props.node.id){
                    setIsSelected(true)
                }
            })
        }
    }, [usersBulkDataSelected])

    return (
        <div className='checkbox-container'>
            {pushing ?
                <div 
                    className={`spinner-border spinner-border-sm text-secondary spinner-selection-column`} 
                    role="status"/>
                :
                <input 
                    type="checkbox"
                    checked={isSelected} 
                    onChange={(e) => selectRow(e)}
                    className='selection-checkbox'
                />
            }
        </div>
    );
};

export default CustomLoadingCell;