import React from 'react';

export default (props) => {
  return (
    <div
      className="ag-custom-loading-cell"
      style={{ paddingLeft: '10px', lineHeight: '25px', fontSize: '1.2em' }}
    >
      <span> {props.loadingMessage}</span>
    </div>
  );
};