import {
  SET_CATEGORIES,
  SET_CLIENT_EVENTS,
  SET_EVENT_BULK_COLUMN_FIELD_MAP,
  SET_EVENT_BULK_COLUMN_FIELD_SELECT,
  SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED,
  SET_EVENT_BULK_DATA,
  SET_EVENT_BULK_DATA_SELECTION_VALID,
  SET_EVENT_BULK_FORMATTED_COLUMNS,
  SET_EVENT_BULK_FORMATTED_DATA,
  SET_EVENT_BULK_FORMATTED_DATA_SELECTED,
  SET_EVENT_BULK_HAS_HEADERS,
  SET_EVENT_BULK_HEADER_FIELDS,
  SET_EVENT_SELECTED,
  SET_FETCHING_CATEGORIES,
  SET_FETCHING_EVENTS
} from '../actions';

const INIT_STATE = {
  events: null,
  isFetchingEvents: false,
  eventSelected: null,
  categories: null,
  isFetchingCategories: false,
  bulkHasHeaders: null,
  bulkHeaderFields: null,
  bulkFormattedColumns: null,
  bulkFormattedData: null,
  bulkFormattedDataSelected: null,
  bulkDataSelectionValid: null,
  bulkColumnFieldMap: null,
  bulkColumnFieldSelect: null,
  bulkColumnReqFieldsSelected: false,
  bulkEvents: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CLIENT_EVENTS:
      return {
        ...state,
        events: action.payload
      };

    case SET_FETCHING_EVENTS:
      return {
        ...state,
        isFetchingEvents: action.payload
      };

    case SET_EVENT_SELECTED:
      return {
        ...state,
        eventSelected: action.payload
      };

    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };

    case SET_FETCHING_CATEGORIES:
      return {
        ...state,
        isFetchingCategories: action.payload
      };

    case SET_EVENT_BULK_HAS_HEADERS:
      return {
        ...state,
        bulkHasHeaders: action.payload
      };

    case SET_EVENT_BULK_HEADER_FIELDS:
      return {
        ...state,
        bulkHeaderFields: action.payload
      };

    case SET_EVENT_BULK_FORMATTED_COLUMNS:
      return {
        ...state,
        bulkFormattedColumns: action.payload
      };

    case SET_EVENT_BULK_FORMATTED_DATA:
      return {
        ...state,
        bulkFormattedData: action.payload
      };

    case SET_EVENT_BULK_FORMATTED_DATA_SELECTED:
      return {
        ...state,
        bulkFormattedDataSelected: action.payload
      };

    case SET_EVENT_BULK_COLUMN_FIELD_MAP:
      return {
        ...state,
        bulkColumnFieldMap: action.payload
      };

    case SET_EVENT_BULK_DATA:
      return {
        ...state,
        bulkEvents: action.payload
      };

    case SET_EVENT_BULK_COLUMN_FIELD_SELECT:
      return {
        ...state,
        bulkColumnFieldSelect: action.payload
      };

    case SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED:
      return {
        ...state,
        bulkColumnReqFieldsSelected: action.payload
      };

    case SET_EVENT_BULK_DATA_SELECTION_VALID:
      return {
        ...state,
        bulkDataSelectionValid: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default reducer;