import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { removeGroupsColumn, removeSelectionColumn, setGroupsColumn, setGroupsColumnOriginalData, setSelectionColumn, setUserBulkFormattedData, setUsersBulkColumnFieldSelect } from "../../../../../../Redux/ClientUsers/actions";
import {
  setUserBulkColumnFieldMap,
  setUserBulkHeaderFields,
} from "../../../../../../Redux/ClientUsers/actions";
import "./CustomColumnHeader.css";
import SELECTORS from "../../../../../../Redux/ClientUsers/selectors";
import { buildBulkData } from "../../../../../../Helpers/UsersHelper";
import { addToastToStack } from "../../../../../../Redux/Toast/actions";


const CustomColumnHeader = (props) => {
  const bulkHeaderFields = useSelector(
    (state) => state.clientUsers.bulkHeaderFields
  );
  const bulkColumnFieldMap = useSelector(SELECTORS.BULKCOLUMNFIELDMAP);
  const bulkFormattedColumns = useSelector(SELECTORS.BULKFORMATTEDCOLUMNS);
  const usersColumnsSelected = useSelector(SELECTORS.USERSCOLUMNSSELECTED);
  const clientUsers = useSelector(SELECTORS.USERS);
  const groupColumn = useSelector(SELECTORS.GROUPCOLUMN);
  const bulkHasHeaders = useSelector(SELECTORS.BULKHASHEADERS);
  const groupColumnOriginalData = useSelector(SELECTORS.GROUPCOLUMNORIGINALDATA);

  const [selectedOption, setSelectedOption] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!usersColumnsSelected){
      props.context.usersColumnsSelected = false
      props.api.showLoadingOverlay()
    } else {
      props.api.hideOverlay()
    }
  },[usersColumnsSelected])

  useEffect(() => {
    if (props.columnApi.columnController.columnDefs.length <= 7) {
      props.api.sizeColumnsToFit();
    }
  }, []);

  useEffect(() => {
    if (bulkColumnFieldMap) {
      const headerSelected = bulkHeaderFields.filter(
        (header) =>
          header.value === bulkColumnFieldMap[props.column.colDef.field]
      );
      setSelectedOption(...headerSelected);
    }
  }, []);

  const checkAllSelected = async () => {
    let columns;
    let data = [];

    if(usersColumnsSelected){
      columns = props.columnApi.columnController.columnDefs.length - 1;
    } else {
      columns = props.columnApi.columnController.columnDefs.length;
    }

    if (bulkColumnFieldMap) {
      let objKeys = Object.keys(bulkColumnFieldMap);
      if (objKeys.length === columns) {
        // First check if there is an email column
        if(Object.values(bulkColumnFieldMap).includes('email')){
          //Check if any column have Groups selected
          if(Object.values(bulkColumnFieldMap).includes('groups')){
            if(!groupColumn){
              let groupsColumn;
              for (const key in bulkColumnFieldMap) {
                if(bulkColumnFieldMap[key] === 'groups'){
                  groupsColumn = key
                }
              }
              let originalGroupData = []
              props.api.forEachNode((node) => {
                originalGroupData.push(node.data[groupsColumn])
              })
              dispatch(setGroupsColumnOriginalData(originalGroupData))
              dispatch(setGroupsColumn(groupsColumn))
            }
          } else if (groupColumn) {
            dispatch(removeGroupsColumn(groupColumn))
            props.api.setColumnDefs(bulkFormattedColumns)
            resetGroupColumnData(groupColumn)
          }
          if(!usersColumnsSelected) {          
            // Obtain the rows data, check if the user exist and if add the selection and userId column
            let newData = []
            props.api.forEachNode((row) => {
              data = buildBulkData([row.data], bulkColumnFieldMap)[0]
              let userId = ''
              clientUsers.forEach((user) => {
                if(user.email === data.email){
                  userId = user.id
                }
              })
              row.data.userId = userId
              newData.push(row.data)
              row.setData(row.data)
            })
            bulkColumnFieldMap.userId = 'userId'
            dispatch(setUserBulkColumnFieldMap(bulkColumnFieldMap))
            dispatch(setUserBulkFormattedData(newData))
            dispatch(setSelectionColumn())
            props.context.bulkColumnFieldMap = bulkColumnFieldMap
            props.context.usersColumnsSelected = true
          }
          props.api.setColumnDefs(bulkFormattedColumns)
          props.api.refreshCells({force: true})
          props.api.redrawRows()
        } else {
          // If there is no email columns show an alert and remove the selection and userId columns if they exist.
          dispatch(addToastToStack('danger', 'Please select an email column.'))
          if(usersColumnsSelected){
            delete bulkColumnFieldMap.userId
            dispatch(setUserBulkColumnFieldMap(bulkColumnFieldMap))
            dispatch(removeSelectionColumn())
            props.api.setColumnDefs(bulkFormattedColumns)
            props.context.usersColumnsSelected = false
          }
        }
      } else {
        // If the columns has not a selected option remove the selection and the userId columns if they exists.
        if(usersColumnsSelected){
          delete bulkColumnFieldMap.userId
          dispatch(setUserBulkColumnFieldMap(bulkColumnFieldMap))
          await dispatch(removeSelectionColumn())
          props.context.usersColumnsSelected = false
        }

        if(groupColumn){
          await dispatch(removeGroupsColumn(groupColumn))
          resetGroupColumnData(groupColumn)
        }

        props.api.setColumnDefs(bulkFormattedColumns)
        props.api.refreshCells({force: true})
        props.api.redrawRows()
      }
    }
  };

  const resetGroupColumnData = (groupColumn) => {
    props.api.forEachNode((node, index) => {
      let nodeData = node.data
      nodeData[groupColumn] = groupColumnOriginalData[index]
      node.setData(nodeData)
    })
  }

  const handleChange = (value) => {
    const previousValue = selectedOption?.value;
    setSelectedOption(value);
    const selectedValue = value.value;

    const headerUpdated = bulkHeaderFields.map((header) => {
      if (header.value === selectedValue && selectedValue !== "select") {
        header.disabled = true;
        return header;
      } else if (header.value === previousValue) {
        header.disabled = false;
        return header;
      } else {
        return header;
      }
    });
    dispatch(setUserBulkHeaderFields(headerUpdated));

    const columnName = props.column.colDef.field;
    const columnFieldMap = bulkColumnFieldMap ? bulkColumnFieldMap : {};

    // Value prev selected validation
    const values = Object.values(columnFieldMap);
    if (values && values.includes(selectedValue)) {
      value.value = "select";
      delete columnFieldMap[columnName];
    } else {
      const isFieldSelected = "select" !== selectedValue;

      if (isFieldSelected) {
        columnFieldMap[columnName] = selectedValue;
      } else {
        delete columnFieldMap[columnName];
      }
    }

    dispatch(setUserBulkColumnFieldMap(columnFieldMap));
    dispatch(setUsersBulkColumnFieldSelect(new Date()));

    checkAllSelected();
  };

  const handleCreate = (value) => {
    const newOption = {
      label: value,
      value: value.replace(/\s/g, ''),
      type: 'kvp',
      disabled: false,
    }

    bulkHeaderFields.push(newOption)
    dispatch(setUserBulkHeaderFields(bulkHeaderFields));
    handleChange(newOption)
  }

  const customStyles = {
    control: (prevState, state) => {
      let isSelected = false;

      if (selectedOption && selectedOption.value !== "select") {
        isSelected = true;
      }

      return {
        ...prevState,
        borderColor: isSelected ? "#B23CFD !important" : "#F93154 !important",
        border: "2px solid",
      };
    },
  };

  return (
    <div className={`${props.show ? "" : "h-100 center-header"}`}>
      {/* {props.enableMenu && menu} */}
      {
        (bulkHasHeaders || props.displayName === 'User Id') &&
          <div className="customHeaderLabel">{typeof props.displayName === 'string' ? props.displayName : props.column.colId}</div>
      }
      {bulkHeaderFields && 0 < bulkHeaderFields.length && (
        <CreatableSelect
          className={`ag-grid-react-select-container mt-1 ${props.show ? "" : "d-none"}`}
          classNamePrefix="ag-grid-react-select"
          options={bulkHeaderFields.filter(
            (header) => header.disabled === false
          )}
          onChange={(value) => handleChange(value)}
          onCreateOption={(value) => handleCreate(value)}
          menuPortalTarget={document.body}
          value={selectedOption}
          styles={customStyles}
        />
      )}
      {/* {props.enableSorting && sort} */}
    </div>
  );
};

export default CustomColumnHeader;
