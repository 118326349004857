import _ from "lodash";
import { MDBBtn, MDBContainer, MDBIcon } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateCell, updateProfileData } from "../../../../Helpers/UsersHelper";
import {
  addUserToGroup,
  createKvp,
  createProfile,
  createUser,
  getClientUsers,
  getUserGroups,
  getUserKvps,
  getUserProfileToCompare,
  setUserBeingPushed, 
  updateKvp,
  updateProfile,
} from "../../../../Redux/ClientUsers/actions";
import CLIENTUSERSELECTORS from "../../../../Redux/ClientUsers/selectors";
import { addToastToStack } from "../../../../Redux/Toast/actions";
import USERSELECTOR from "../../../../Redux/User/selectors";
import Dropzone from "../../../Dropzone/Dropzone";
import "./UserBulkUpdate.css";
import UserBulkUpdateGrid from "./UserBulkUpdateGrid/UserBulkUpdateGrid";

const UserBulkUpdate = () => {
  const userBulkData = useSelector(CLIENTUSERSELECTORS.USERSBULKDATA);
  const usersBulkDataSelected = useSelector(CLIENTUSERSELECTORS.USERSBULKDATASELECTED);
  const userClientId = useSelector(USERSELECTOR.USERCLIENTID);
  const userKvps = useSelector(CLIENTUSERSELECTORS.USERKVPS);
  const dispatch = useDispatch();
  const [openDropzone, setOpenDropzone] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);

  const addData = () => {
    setOpenDropzone(!openDropzone);
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onPush = async () => {
    // console.log("Pushing...", usersBulkDataSelected);

    for (const user of usersBulkDataSelected) {
      dispatch(setUserBeingPushed(user.rowId));
      let isRowValid = []
      for (const key in user) {
        if(!(key === 'userId') && !(key === 'groups') && !(key === 'rowId')){
          const validation = validateCell(key, user[key].data)
          isRowValid.push(validation.isValid)
        }
      }
      if(!isRowValid.includes(false)){
        let userToCreate = {
          clientId: userClientId,
        };
        let profileToSend = {};
        let userGroups = {
          userId: "",
          values: [],
        };
        let kvpToSend = [];
        const values = Object.values(user);
        for (const val of values) {
          if (val.type === "userId") {
            userToCreate[val.value] = val.data;
            profileToSend[val.value] = val.data;
            userGroups[val.value] = val.data;
          }
          if (val.type === "account") {
            userToCreate[val.value] = val.data;
          }
          if (val.type === "profile") {
            profileToSend[val.value] = val.data;
          }
          if (val.type === "groups" && val.data.length > 0) {
            val.data.forEach((group) => {
              userGroups.values.push(group.value);
            });
          }
          if (val.type === "kvp") {
            let userId = user.userId.data;
            kvpToSend.push({
              key: val.value,
              value: val.data,
              public: true,
              userId: userId,
            });
          }
        }
  
        if (!userToCreate.userId) {
          await handleCreateUser(
            userToCreate,
            profileToSend,
            kvpToSend,
            userGroups
          );
        } else {
          await handleUpdateUser(profileToSend, kvpToSend, userGroups);
        }
      } else {
        dispatch(addToastToStack('danger', 'The row is not valid, please check.'))
        await Promise.all([
          timeout(1000)
        ]);
      }
    }

    // After the users are created. I request the clients users to update the users tab
    dispatch(getClientUsers(1, 500, userClientId));
    dispatch(setUserBeingPushed(null));
  };

  const handleCreateUser = async (
    userToCreate,
    profileToSend,
    kvpToSend,
    userGroups
  ) => {
    await dispatch(createUser(userToCreate))
      .then(async (userId) => {
        const profileValues = Object.values(profileToSend);
        if (profileValues.length > 1) {
          profileToSend.userId = userId;
          await dispatch(createProfile(profileToSend))
            .then(async (newProfile) => {
              await dispatch(getUserKvps(userId)).then(async (kvps) => {
                if (kvpToSend.length > 0) {
                  for (const kvp of kvpToSend) {
                    if (kvp.value) {
                      kvp.userId = userId;
                      let kvpId = "";
                      kvps.forEach((el) => {
                        if (el.key === kvp.key) {
                          kvpId = el.id;
                        }
                      });
                      if (!kvpId) {
                        await dispatch(createKvp(kvp))
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      } else {
                        await dispatch(updateKvp(kvp))
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }
                    }
                  }
                }
              });
            })
            .catch((err) => {});
        }

        if (userGroups.values.length > 0) {
          userGroups.userId = userId;
          await dispatch(getUserGroups(userGroups.userId)).then(async (res) => {
            console.log(res);
            if (res.length > 0) {
              const groupsToAdd = _.difference(userGroups.values, res);
              if (groupsToAdd.length > 0) {
                await dispatch(addUserToGroup(userGroups.userId, groupsToAdd))
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            } else {
              await dispatch(
                addUserToGroup(userGroups.userId, userGroups.values)
              )
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateUser = async (profileToSend, kvpToSend, userGroups) => {
    const profileValues = Object.values(profileToSend);
    if (profileValues.length > 1) {
      await dispatch(getUserProfileToCompare(profileToSend.userId))
        .then(async (origProfile) => {
          const newProfile = updateProfileData(origProfile, profileToSend)
          await dispatch(updateProfile(newProfile))
            .then((res) => {
              
            })
            .catch((err) => {
              dispatch(addToastToStack('danger', `Unable to update the profile of user ${profileToSend.userId}`))
            });
        })
    }

    if (kvpToSend.length > 0) {
      await dispatch(getUserKvps(profileToSend.userId))
        .then(async (kvps) => {
          for (const kvp of kvpToSend) {
            if (kvp.value) {
              let kvpId = "";
              kvps.forEach((el) => {
                if (el.key === kvp.key) {
                  kvpId = el.id;
                }
              });
              if (!kvpId) {
                await dispatch(createKvp(kvp))
                  .then((res) => {
                  })
                  .catch((err) => {
                    dispatch(addToastToStack('danger', `Unable to create a kvp for user ${profileToSend.userId}`))
                  });
              } else {
                await dispatch(updateKvp(kvp))
                  .then((res) => {
                  
                  })
                  .catch((err) => {
                    dispatch(addToastToStack('danger', `Unable to update a kvp for user ${profileToSend.userId}`))
                  });
              }
            }
          }
        })
        .catch((err) => {
          
        });
    }

    if (userGroups.values.length > 0) {
      await dispatch(getUserGroups(userGroups.userId))
        .then(async (hasGroups) => {
          if (hasGroups.length > 0) {
            const groupsToAdd = _.difference(userGroups.values, hasGroups);
            if (groupsToAdd.length > 0) {
              await dispatch(addUserToGroup(userGroups.userId, groupsToAdd))
                .then((res) => {
                })
                .catch((err) => {
                  dispatch(addToastToStack('danger', `Unable to add groups to user ${userGroups.userId}`))
                });
            }
          } else {
            await dispatch(addUserToGroup(userGroups.userId, userGroups.values))
              .then((res) => {
              })
              .catch((err) => {
                dispatch(addToastToStack('danger', `Unable to add groups to user ${userGroups.userId}`))
              });
          }
        })
        .catch((err) => {
          
        });
    }
  };

  const onDelete = () => {
    // console.log("Deleting...");
  };

  useEffect(() => {
    if (userBulkData) {
      setShowDropzone(false);
    }
  }, [userBulkData]);

  return (
    <MDBContainer fluid>
      <div className="UserBulkUpdate">
        <div className="users-bulk-grid">
          {userBulkData && <UserBulkUpdateGrid />}
          <Dropzone
            openDropzone={openDropzone}
            show={showDropzone}
            setOpenDropzone={setOpenDropzone}
          />
        </div>
        <div className="users-bulk-buttons-container">
          <MDBBtn color="secondary" type="button" onClick={addData}>
            <MDBIcon icon="file-import" />
            Import Elements
          </MDBBtn>
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onDelete}
            disabled={usersBulkDataSelected ? false : true}
          >
            <MDBIcon icon="trash" />
            Remove Selected
          </MDBBtn>
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onPush}
            disabled={usersBulkDataSelected ? false : true}
          >
            <MDBIcon icon="upload" />
            Push Selected
          </MDBBtn>
        </div>
      </div>
    </MDBContainer>
  );
};

export default UserBulkUpdate;
