import { AgGridReact } from "ag-grid-react";
import { MDBBtn, MDBContainer } from "mdbreact";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultUserImg from "../../../../../assets/images/default-user-image.png";
import { setFetchingUserProfileImage, setKvpActive, setNewProfileImageFile, setUserProfileImage } from "../../../../../Redux/ClientUsers/actions";
import CLIENTUSERSSELECTOR from "../../../../../Redux/ClientUsers/selectors";
import ReactCrop from 'react-image-crop';
import CustomKeyEditor from "../CustomKeyEditor/CustomKeyEditor";
import CustomValueEditor from "../CustomValueEditor/CustomValueEditor";
import 'react-image-crop/dist/ReactCrop.css';
import "./ProfileGrid.css";
import { addToastToStack } from "../../../../../Redux/Toast/actions";
import environment from "../../../../../environments/environment";

const { APP_API_URL } = environment;

const ProfileGrid = () => {
  const isFetchingProfile = useSelector(CLIENTUSERSSELECTOR.ISFETCHINGPROFILE);
  const isFetchingProfileImage = useSelector(CLIENTUSERSSELECTOR.ISFETCHINGPROFILEIMAGE);
  const userProfile = useSelector(CLIENTUSERSSELECTOR.USERPROFILE);
  const userProfileImage = useSelector((state) => state.clientUsers.userProfileImage);
  const userKvps = useSelector(CLIENTUSERSSELECTOR.USERKVPS);
  const isAddingKvp = useSelector(CLIENTUSERSSELECTOR.ISADDINGKVP);
  const isKvpActive = useSelector(CLIENTUSERSSELECTOR.ISKVPACTIVE);
  const userSelected = useSelector(CLIENTUSERSSELECTOR.USERSELECTED);
  const newProfileImageFile = useSelector(CLIENTUSERSSELECTOR.NEWPROFILEIMAGEFILE);
  const dispatch = useDispatch();
  const [gridData, setGridData] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [newImageSetted, setNewImageSetted] = useState(false);
  const [imageToCrop, setImageToCrop] = useState();
  const [crop, setCrop] = useState({aspect: 1/1});
  const imageProfileInput = useRef()
  const imgRef = useRef()

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    // floatingFilter: true,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Key",
      field: "field",
      suppressSizeToFit: true,
      flex: .7,
      editable: false,
      cellRenderer: 'customKeyEditor',
    },
    {
      headerName: "Value",
      field: "value",
      suppressSizeToFit: true,
      flex: 1,
      editable: false,
      cellRenderer: 'customValueEditor',
    },
  ]);

  const profileToGridData = (profile) => {
    const keys = Object.keys(profile);
    return keys.map((fieldName) => ({
      field: fieldName,
      value: userProfile[fieldName],
    }));
  };

  const kvpsToGridData = (kvps) => {
    const data = kvps.map((kvp) => {
      return {
        field: kvp.key,
        value: kvp.value,
      }
    })
    return data
  };

  const setProfileData = () => {
    const gridData = profileToGridData(userProfile);
    setGridData(gridData);
    dispatch(setKvpActive(false))
  }

  const setKvpsData = () => {
    const gridData = kvpsToGridData(userKvps);
    setGridData(gridData);
    dispatch(setKvpActive(true))
  }

  useEffect(() => {
    if (!isFetchingProfile && userProfile) {
      const gridData = profileToGridData(userProfile);
      setGridData(gridData);
    }
  }, [isFetchingProfile, userProfile]);

  useEffect(() => {
    if(isKvpActive){
      setKvpsData()
    }
  }, [isAddingKvp]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const openImageFileInput = () => {
    imageProfileInput.current.click();
  }

  const handleNewProfileImage = (e) => {
    try {
      const img = e.target.files[0]
      if(img.type === 'image/png' || img.type ===  'image/jpeg'	|| img.type ===  'image/gif'){
        const url = URL.createObjectURL(img)
        dispatch(setNewProfileImageFile(img))
        dispatch(setUserProfileImage(url))
      } else {
        dispatch(addToastToStack('danger', 'Please select a valid image file.'))
      }
    } catch (error) {
      
    }
  }

  const makeClientCrop = async (crop) => {
    if (imageToCrop && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageToCrop,
        crop,
        newProfileImageFile.name
      );
      setNewImageSetted(true)
      dispatch(setUserProfileImage(croppedImageUrl))
      setCrop({aspect: 1/1})
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width + 300;
    canvas.height = crop.height + 300;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      (crop.x) * scaleX,
      (crop.y) * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width + 300,
      crop.height + 300
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        dispatch(setNewProfileImageFile(blob))
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    });
  }

  const onImageLoaded = (img) => {
    setNewImageSetted(false)
    setImageToCrop(img)
  }

  const confirmImgCrop = (e) => {
    if(e.keyCode === 13){
      if(!newImageSetted){
        makeClientCrop(crop);
      }
    }
    if(e.keyCode === 27){
      setCrop({aspect: 1/1})
    }
  }

  return (
    <MDBContainer fluid>
      {userProfile && (
        <div className="ProfileGrid">
          <div className="profile-header-container">
            { <div className="img-container" onKeyDown={(e) => confirmImgCrop(e)} onBlur={(e) => confirmImgCrop(e)}>
                <ReactCrop 
                  src={userProfileImage ? userProfileImage : defaultUserImg} 
                  onChange={(crop) => setCrop(crop)}
                  crop={crop}
                  onImageLoaded={(img) => onImageLoaded(img)}
                  disabled={newProfileImageFile ? false : true}
                  onLoad={() => console.log('load')}
                  className={isFetchingProfileImage ? 'd-none' : ""}
                />
                <div className={isFetchingProfileImage ? 'spinner-border text-secondary center-spinner' : "d-none"} role="status"/>
              </div>
            }
            <div className='profile-title-container'>
              <label className='profile-image-input' htmlFor='profileImage' onClick={() => openImageFileInput()}>
                Upload a new Image
                <input 
                  type='file' 
                  placeholder='Upload a new image'
                  name='profileImage'
                  onChange={(e) => handleNewProfileImage(e)}
                  ref={imageProfileInput}
                  className='d-none'
                /> 
              </label>
              <span className='profile-title-user-names'>{userSelected.data.firstName} {userSelected.data.surname}</span>
              <span className='profile-title-user-email'>{userSelected.data.email}</span>
            </div>
            
            <div className='profile-kvp-switch'>
              <MDBBtn color='secondary' onClick={(e) => setProfileData(e)}>
                Profile
              </MDBBtn>
              <MDBBtn color='secondary' onClick={(e) => setKvpsData(e)}>
                KVPs
              </MDBBtn>
            </div>
          </div>
          <div className="grid-container ag-theme-alpine">
            <AgGridReact
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={gridData}
              frameworkComponents={{ 
                customValueEditor: CustomValueEditor,
                customKeyEditor: CustomKeyEditor,
              }}
            ></AgGridReact>
          </div>
        </div>
      )}
    </MDBContainer>
  );
};

export default ProfileGrid;
