import {
  MDBBtn,
  MDBCollapse,
  MDBNav,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { PATHS } from "../../Constants/Paths";
import { logout } from "../../Redux/Auth/actions";
import { fetchClients } from "../../Redux/Clients/actions";
import { setUserProfile, setUserProfileToUpdate } from "../../Redux/ClientUsers/actions";
import { setActiveTab } from "../../Redux/Tabs/actions";
import { setUserClientId } from "../../Redux/User/actions";
import storageService from "../../Services/Storage.service";
import "./Header.css";
const { REACT_APP_NAME } = process.env;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const activeTab = useSelector((state) => state.tabs.activeTab);
  const userRole = useSelector((state) => state.user.userRoles);
  const userClientId = useSelector((state) => state.user.userClientId);
  const clients = useSelector((state) => state.clients.clientsList);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (userRole.find((role) => role === "JolokiaAdmin")) {
      dispatch(fetchClients());
    }
  }, [isAuthenticated]);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const onLogout = () => {
    dispatch(logout()).then((res) => {
      history.push(PATHS.login);
    });
  };

  const goHome = () => {
    if (isAuthenticated) {
      history.push(PATHS.dashboard);
    } else {
      history.push(PATHS.login);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      dispatch(setActiveTab(tab));
    }
  };

  const handleChange = ({ value }) => {
    dispatch(setUserClientId(value));
    storageService.set('clientId', value)
    dispatch(setUserProfile(null));
    dispatch(setUserProfileToUpdate(null));
  };

  return (
    <MDBNavbar light expand="md">
      <MDBNavbarBrand>
        <h2 className="font-weight-light pointer" onClick={() => goHome()}>
          {REACT_APP_NAME}
        </h2>
      </MDBNavbarBrand>
      <MDBNavbarToggler onClick={() => toggleCollapse()} />
      {isAuthenticated && location.pathname !== PATHS.dashboard && (
        <MDBNav className="nav-tabs ">
          <MDBNavItem
            active={activeTab === 1}
            onClick={() => toggle(1)}
            role="tab"
          >
            {location.pathname === PATHS.usersModule ? "Users" : "Events"}
          </MDBNavItem>
          <MDBNavItem
            active={activeTab === 2}
            onClick={() => toggle(2)}
            role="tab"
          >
            Bulk {location.pathname === PATHS.usersModule ? "Update" : "Import"}
          </MDBNavItem>
        </MDBNav>
      )}
      <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
        <MDBNavbarNav right className="d-flex align-items-center">
          {isAuthenticated &&
            userRole.find((role) => role === "JolokiaAdmin") && (
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={clients}
                onChange={(value) => handleChange(value)}
                value={clients.filter(
                  (client) => client.value === userClientId
                )}
              />
            )}
          {isAuthenticated && (
            <MDBNavItem>
              <MDBBtn
                outline
                color="secondary"
                type="button"
                onClick={goHome}
                disabled={PATHS.dashboard === location.pathname}
              >
                Dashboard
              </MDBBtn>
            </MDBNavItem>
          )}
          {isAuthenticated && (
            <MDBNavItem active>
              <MDBBtn
                outline
                color="secondary"
                type="button"
                onClick={onLogout}
              >
                Sign out
              </MDBBtn>
            </MDBNavItem>
          )}
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};

export default Header;
