import { MDBBtn, MDBContainer } from "mdbreact";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addingKvp, getUserProfileImage, getUserProfileToCompare, setIsUserProfileEdited, setNewKvpKey, setNewKvpValue, setNewProfileImageFile, setUserKvps, setUserProfile, setUserProfileToUpdate, updateProfile, updateProfileImage } from "../../../../Redux/ClientUsers/actions";
import CLIENTUSERSSELECTOR from "../../../../Redux/ClientUsers/selectors";
import { addToastToStack } from "../../../../Redux/Toast/actions";
import ProfileGrid from "./ProfileGrid/ProfileGrid";
import _ from 'lodash';
import "./UserManagement.css";
import UsersGrid from "./UsersGrid/UsersGrid";

const UserManagement = () => {
  const userSelected = useSelector(CLIENTUSERSSELECTOR.USERSELECTED);
  const userProfile = useSelector(CLIENTUSERSSELECTOR.USERPROFILE);
  const userProfileToUpdate = useSelector(CLIENTUSERSSELECTOR.USERPROFILETOUPDATE);
  const isKvpActive = useSelector(CLIENTUSERSSELECTOR.ISKVPACTIVE);
  const userKvps = useSelector(CLIENTUSERSSELECTOR.USERKVPS);
  const isAddingKvp = useSelector(CLIENTUSERSSELECTOR.ISADDINGKVP);
  const newProfileImageFile = useSelector(CLIENTUSERSSELECTOR.NEWPROFILEIMAGEFILE);
  const isFetchingProfile = useSelector(CLIENTUSERSSELECTOR.ISFETCHINGPROFILE);
  const isUserProfileEdited = useSelector(CLIENTUSERSSELECTOR.ISUSERPROFILEEDITED);
  const dispatch = useDispatch();

  const onCreateUser = () => {
    // console.log("Creating user...");
  };

  const onCreateProfile = () => {
    // console.log("Creating profile...");
  };

  const onUpdateProfile = () => {
    if(userProfileToUpdate){
      dispatch(getUserProfileToCompare(userProfileToUpdate.userId))
        .then((profile) => {
          if(_.isEqual(profile, userProfileToUpdate)){
            dispatch(addToastToStack('warning', 'There are no changes to update.'))
          } else {
            dispatch(updateProfile(userProfileToUpdate))
              .then((res) => {
                dispatch(addToastToStack('success', 'Profile updated.'))
                dispatch(setIsUserProfileEdited(false))
              })
              .catch((err) => {
                dispatch(addToastToStack('danger', 'Unable to update the profile.'))
                dispatch(setUserProfile(profile))
                dispatch(setUserProfileToUpdate(profile))
                dispatch(setIsUserProfileEdited(false))
              })
          }
        })
    }

    if(newProfileImageFile){
      dispatch(updateProfileImage(newProfileImageFile, userProfileToUpdate.userId))
        .then((res) => {
          dispatch(addToastToStack('success', 'Image updated.'))
          dispatch(setNewProfileImageFile(null))
        })
        .catch((err) => {
          dispatch(getUserProfileImage())
          dispatch(addToastToStack('danger', 'Unable to update the profile image.'))
          dispatch(setNewProfileImageFile(null))
        })
    }
  };

  const onAddKvp = () => {
    userKvps.push({
      key:"",
      value:""
    })
    dispatch(setUserKvps(userKvps))
    dispatch(addingKvp(true))
  }

  const onCancelProfileUpdate = () => {
    dispatch(getUserProfileToCompare(userProfileToUpdate.userId))
      .then((profile) => {
        dispatch(setUserProfile(profile))
        dispatch(setUserProfileToUpdate(profile))
        dispatch(setNewProfileImageFile(null))
        dispatch(setIsUserProfileEdited(false))
      })
  }

  const onCancelKvpCreation = async () => {
    userKvps.pop()
    await dispatch(setUserKvps(userKvps));
    dispatch(setNewKvpKey(""))
    dispatch(setNewKvpValue(""))
    dispatch(addingKvp(false))
  }

  return (
    <MDBContainer fluid>
      <div className="UserManagement">
        <div className="users-grid">
          <UsersGrid></UsersGrid>
        </div>
        <div className="users-button-container">
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onCreateUser}
            disabled
          >
            Create User
          </MDBBtn>
        </div>
        {isFetchingProfile && (
          <div className="profile">
            <div className="spinner-container">
              <div
                className="spinner-border text-secondary"
                role="status"
              ></div>
              <span>Loading Profile...</span>
            </div>
          </div>
        )}
        {!userSelected && (
          <div className="profile">
            <div className="noprofile-container">
              <span>Please Select User</span>
            </div>
          </div>
        )}
        {userSelected && !isFetchingProfile && !userProfile && (
          <div className="profile">
            <div className="noprofile-container">
              <span>No User Profile</span>
              <MDBBtn
                color="secondary"
                type="button"
                onClick={onCreateProfile}
                disabled
              >
                Create Profile
              </MDBBtn>
            </div>
          </div>
        )}
        {!isFetchingProfile && userProfile && (
          <div className="profile-grid">
            <ProfileGrid></ProfileGrid>
          </div>
        )}
        {!isFetchingProfile && userProfile && !isKvpActive && (
          <div className="profile-button-container">
            <MDBBtn
              color="secondary"
              type="button"
              onClick={() => onUpdateProfile()}
            >
              Update Profile
            </MDBBtn>
            <MDBBtn
              color="secondary"
              type="button"
              onClick={() => onCancelProfileUpdate()}
              disabled={(isUserProfileEdited || newProfileImageFile) ? false : true }
            >
              Cancel
            </MDBBtn>
          </div>
        )}
        {!isFetchingProfile && userProfile && isKvpActive && (
          <div className="profile-button-container">
            <MDBBtn
              color="secondary"
              type="button"
              onClick={() => onAddKvp()}
              disabled={isAddingKvp}
            >
              Add Kvp
            </MDBBtn>
            {isAddingKvp && 
              <MDBBtn
                color="secondary"
                type="button"
                onClick={() => onCancelKvpCreation()}
              >
                Cancel
              </MDBBtn>
            }
          </div>
        )}
      </div>
    </MDBContainer>
  );
};

export default UserManagement;
