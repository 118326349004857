export const eventsBulkHeaderFields = [
  {
    label: "Select field...",
    value: "select",
    disabled: false,
    required: false,
  },
  {
    label: "Name",
    value: "name",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Description",
    value: "description",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Duration",
    value: "duration",
    disabled: false,
    type: 'video',
    required: true,
  },
  {
    label: "Start Time",
    value: "startTime",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "PreRoll Time",
    value: "preRoll",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Disable PreRoll",
    value: "disablePreroll",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Conf. Meeting URL",
    value: "eventFeaturesZoomURL",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Event Categories",
    value: "categories",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Player Page",
    value: "eventFeaturesCustomPlayerPageOverrideSnippetId",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Q&A",
    value: "eventFeaturesQuestionAndAnswer",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Event Registration",
    value: "eventFeaturesRegistration",
    disabled: false,
    type: 'event',
    required: true,
  },
  {
    label: "Voting",
    value: "eventFeaturesVoting",
    disabled: false,
    type: 'profile',
    required: true,
  },
  {
    label: "Thumbnail URL",
    value: "thumbnail",
    disabled: false,
    type: 'video',
    required: true,
  },
  {
    label: "Autoplay",
    value: "autoplay",
    disabled: false,
    type: 'video',
    required: true,
  }
];
