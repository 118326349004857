import eventService from '../../Services/Event.service';
import {
    SET_CATEGORIES,
    SET_CLIENT_EVENTS,
    SET_EVENT_BULK_COLUMN_FIELD_MAP,
    SET_EVENT_BULK_COLUMN_FIELD_SELECT,
    SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED,
    SET_EVENT_BULK_DATA,
    SET_EVENT_BULK_DATA_SELECTION_VALID,
    SET_EVENT_BULK_FORMATTED_COLUMNS,
    SET_EVENT_BULK_FORMATTED_DATA,
    SET_EVENT_BULK_FORMATTED_DATA_SELECTED,
    SET_EVENT_BULK_HAS_HEADERS,
    SET_EVENT_BULK_HEADER_FIELDS,
    SET_EVENT_SELECTED,
    SET_FETCHING_CATEGORIES,
    SET_FETCHING_EVENTS
} from '../actions';
import { logout } from '../Auth/actions';
import { setClientIdRequested } from '../Clients/actions';

export const getClientEvents = (client) => {
    return (dispatch) => {
        dispatch(setFetchingEvents(true))
        dispatch(setClientEvents([]))

        return new Promise((resolve, reject) => {
            eventService.getEvents(client)
                .then((data) => {
                    dispatch(setClientEvents(data))
                    dispatch(setFetchingEvents(false))
                    dispatch(setClientIdRequested(client))
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    dispatch(setFetchingEvents(false))
                    reject(false)
                })
        })
    }
};

export const getClientCategories = () => {
    return (dispatch) => {
        dispatch(setFetchingCategories(true))

        return new Promise((resolve, reject) => {
            eventService.getCategories()
                .then((data) => {
                    dispatch(setClientCategories(data))
                    dispatch(setFetchingCategories(false))
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    dispatch(setFetchingCategories(false))
                    reject(false)
                })
        })
    }
};

export const resetEventsBulkHeaderFields = (eventsBulkHeaderFields) => {
    return (dispatch) => {
        const resetParam = eventsBulkHeaderFields.map((header) => {
            header.disabled = false
            return header
        })
        dispatch(setEventsBulkHeaderFields(resetParam))
    }
}

export const setFetchingEvents = (boolean) => ({
    type: SET_FETCHING_EVENTS,
    payload: boolean
})

export const setClientEvents = (events) => ({
    type: SET_CLIENT_EVENTS,
    payload: events
})

export const setEventSelected = (event) => ({
    type: SET_EVENT_SELECTED,
    payload: event
})

export const setFetchingCategories = (boolean) => ({
    type: SET_FETCHING_CATEGORIES,
    payload: boolean
})

export const setClientCategories = (events) => ({
    type: SET_CATEGORIES,
    payload: events
})

export const setEventsBulkHasHeaders = (boolean) => ({
    type: SET_EVENT_BULK_HAS_HEADERS,
    payload: boolean
});

export const setEventsBulkHeaderFields = (data) => ({
    type: SET_EVENT_BULK_HEADER_FIELDS,
    payload: data
});

export const setEventsBulkData = (data) => ({
    type: SET_EVENT_BULK_DATA,
    payload: data
})

export const setEventsBulkFormattedColumns = (data) => ({
    type: SET_EVENT_BULK_FORMATTED_COLUMNS,
    payload: data
});

export const setEventsBulkFormattedData = (data) => ({
    type: SET_EVENT_BULK_FORMATTED_DATA,
    payload: data
});

export const setEventsBulkFormattedDataSelected = (data) => ({
    type: SET_EVENT_BULK_FORMATTED_DATA_SELECTED,
    payload: data
});

export const setEventsBulkColumnFieldMap = (data) => ({
    type: SET_EVENT_BULK_COLUMN_FIELD_MAP,
    payload: data
});

export const setEventsBulkColumnFieldSelect = (data) => ({
    type: SET_EVENT_BULK_COLUMN_FIELD_SELECT,
    payload: data
});

export const setEventsBulkColumnReqFieldsSelected = (data) => ({
    type: SET_EVENT_BULK_COLUMN_REQ_FIELDS_SELECTED,
    payload: data
});

export const setEventsBulkDataSelectionValid = (data) => ({
    type: SET_EVENT_BULK_DATA_SELECTION_VALID,
    payload: data
});
