import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addingKvp, createKvp, setNewKvpKey, setNewKvpValue } from '../../../../../Redux/ClientUsers/actions';
import CLIENTUSERSSELECTOR from '../../../../../Redux/ClientUsers/selectors';
import { addToastToStack } from '../../../../../Redux/Toast/actions';
import './CustomKeyEditor.css'

const CustomKeyEditor = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(props.value);
    const isKvpActive = useSelector(CLIENTUSERSSELECTOR.ISKVPACTIVE);
    const newKvpValue = useSelector(CLIENTUSERSSELECTOR.NEWKVPVALUE);
    const newKvpKey = useSelector(CLIENTUSERSSELECTOR.NEWKVPKEY);
    const userSelected = useSelector(CLIENTUSERSSELECTOR.USERSELECTED);
    const dispatch = useDispatch();
    const refInput = useRef();
    
    const openEditor = () => {
        if(props.data.field === '' || props.data.field === newKvpKey){
            setIsEditing(true)
        } else {
            return
        }
    };

    const stopEditing = (boolean) => {
        setIsEditing(false)
        if(boolean){
            setValue(value)
        } else {
            setValue(props.value)
        }
    };

    const confirmEdit = async (e) => {
        if(e.keyCode === 27){
            stopEditing(false)
        } else if (e.keyCode === 13 && isKvpActive) {
            handleKvpEdit()
        }
    };

    const handleKvpEdit = async () => {
        if(value !== props.data.field){
            await dispatch(setNewKvpKey(value))
            updateData(value)

            if(props.data.field && props.data.value){
                await dispatch(createKvp({
                    key: props.data.field,
                    value: props.data.value,
                    userId: userSelected.data.id,
                }))
                    .then((res) => {
                        if(res){
                            dispatch(addingKvp(false))
                            setValue(value)
                            dispatch(setNewKvpKey(""))
                            dispatch(setNewKvpValue(""))
                            stopEditing(true)
                            dispatch(addToastToStack('success', 'New KVP created.'))
                        }
                    })
                    .catch((err) => {
                        dispatch(addToastToStack('danger', 'Fails to add a new Kvp please try again.'))
                        dispatch(addingKvp(false))
                        dispatch(setNewKvpKey(""))
                        dispatch(setNewKvpValue(""))
                        stopEditing(false)
                        removeData()
                    })
            } else {
                setValue(value)
                stopEditing(true)
            }    
        } else {
            setLoading(false)
            stopEditing(false)
        }
    }

    const updateData = (value) => {
        props.data.field = value
        props.api.onFilterChanged();
        props.api.refreshCells()
    };

    const removeData = () => {
        setValue("")
        props.data.value = ""
        props.data.field = ""
        props.api.onFilterChanged();
        props.api.refreshCells()
    }

    return (
        <div onDoubleClick={(e) => openEditor()} className={ `custom-cell-editor-wrapper`}>   
            { isEditing ?
                <div className='custom-cell-editor-container'> 
                    <input 
                        type="text"
                        ref={refInput}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        className={`custom-cell-editor`}
                        onBlur={() => stopEditing(false)}
                        onKeyDown={(e) => confirmEdit(e)}
                        autoFocus
                    />
                    {loading &&
                        <div 
                            className={`spinner-border spinner-border-sm text-secondary spinner-custom-cell`} 
                            role="status"
                        />
                    }
                </div>
                :
                props.value
            }
        </div>
    );
}; 

export default CustomKeyEditor;