import { MDBBtn, MDBContainer, MDBIcon } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setEventsBulkColumnFieldMap,
  setEventsBulkData,
  setEventsBulkFormattedData,
  setEventsBulkFormattedDataSelected,
  setEventsBulkHasHeaders,
} from "../../../../Redux/ClientEvents/actions";
import Dropzone from "../../../Dropzone/Dropzone";
import "./EventBulkUpdate.css";
import EventBulkUpdateGrid from "./EventsBulkUpdateGrid/EventsBulkUpdateGrid";

const EventBulkUpdate = () => {
  const dispatch = useDispatch();
  const [openDropzone, setOpenDropzone] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);
  const bulkEvents = useSelector((state) => state.clientEvents.bulkEvents);
  const bulkColumnReqFieldsSelected = useSelector(
    (state) => state.clientEvents.bulkColumnReqFieldsSelected
  );
  const bulkFormattedData = useSelector(
    (state) => state.clientEvents.bulkFormattedData
  );
  const bulkDataSelectionValid = useSelector(
    (state) => state.clientEvents.bulkDataSelectionValid
  );
  const bulkFormattedDataSelected = useSelector(
    (state) => state.clientEvents.bulkFormattedDataSelected
  );

  const addData = () => {
    setOpenDropzone(!openDropzone);
  };

  const onPush = () => {
    // console.log("Pushing...");
  };

  const onDelete = () => {
    if (bulkFormattedData && bulkFormattedDataSelected) {
      const selectedIdxs = bulkFormattedDataSelected.map((d) => d.idx);
      const nonDeletedRows = bulkFormattedData.filter(
        (d) => !selectedIdxs.includes(d.idx)
      );

      dispatch(setEventsBulkFormattedData(nonDeletedRows));
      dispatch(setEventsBulkFormattedDataSelected(null));

      console.log("nonDeletedRows", !!nonDeletedRows);
      console.log("nonDeletedRows length", 0 === nonDeletedRows.length);
      if (!nonDeletedRows || 0 === nonDeletedRows.length) {
        dispatch(setEventsBulkHasHeaders(false));
        dispatch(setEventsBulkData(null));
        dispatch(setEventsBulkColumnFieldMap(null));
      }
    }
  };

  useEffect(() => {
    setShowDropzone(!bulkEvents);
  }, [bulkEvents]);

  return (
    <MDBContainer fluid>
      <div className="EventsBulkUpdate">
        <div className="events-bulk-grid ">
          {bulkEvents && <EventBulkUpdateGrid />}
          <Dropzone
            openDropzone={openDropzone}
            show={showDropzone}
            setOpenDropzone={setOpenDropzone}
          />
        </div>
        <div className="events-bulk-buttons-container">
          <MDBBtn color="secondary" type="button" onClick={addData}>
            <MDBIcon icon="file-import" />
            Import Elements
          </MDBBtn>
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onDelete}
            disabled={
              !(
                bulkColumnReqFieldsSelected &&
                bulkFormattedDataSelected &&
                0 < bulkFormattedDataSelected.length
              )
            }
          >
            <MDBIcon icon="trash" />
            Remove Selected
          </MDBBtn>
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onPush}
            disabled={
              !(
                bulkColumnReqFieldsSelected &&
                bulkFormattedDataSelected &&
                0 < bulkFormattedDataSelected.length
              ) || !bulkDataSelectionValid
            }
          >
            <MDBIcon icon="upload" />
            Push Selected
          </MDBBtn>
        </div>
      </div>
    </MDBContainer>
  );
};

export default EventBulkUpdate;
