export class StorageService {
    set(key, value) {
      localStorage.setItem(key, value);
    }
  
    get(key) {
      return localStorage.getItem(key);
    }
  
    remove(key) {
      localStorage.removeItem(key);
    }
  
    clear() {
      localStorage.clear();
    }
  }
  
  const storageService = new StorageService();
  export default storageService;
  