import { isArguments } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateCell } from '../../../../../Helpers/UsersHelper';
import { addingKvp, createKvp, setIsUserProfileEdited, setNewKvpKey, setNewKvpValue, setUserKvps, setUserProfileToUpdate, updateKvp } from '../../../../../Redux/ClientUsers/actions';
import CLIENTUSERSSELECTOR from '../../../../../Redux/ClientUsers/selectors';
import { addToastToStack } from '../../../../../Redux/Toast/actions';
import './CustomValueEditor.css'

const CustomValueEditor = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editedSuccess, setEditedSuccess] = useState(false);
    const [editedFails, setEditedFails] = useState(false);
    const [validationFails, setValidationFails] = useState(false);
    const [value, setValue] = useState(props.value);
    const userProfileToUpdate = useSelector(CLIENTUSERSSELECTOR.USERPROFILETOUPDATE);
    const isKvpActive = useSelector(CLIENTUSERSSELECTOR.ISKVPACTIVE);
    const newKvpValue = useSelector(CLIENTUSERSSELECTOR.NEWKVPVALUE);
    const newKvpKey = useSelector(CLIENTUSERSSELECTOR.NEWKVPKEY);
    const userSelected = useSelector(CLIENTUSERSSELECTOR.USERSELECTED);
    const isAddingKvp = useSelector(CLIENTUSERSSELECTOR.ISADDINGKVP);
    const userKvps = useSelector(CLIENTUSERSSELECTOR.USERKVPS);
    const dispatch = useDispatch();
    const refInput = useRef();
    
    const openEditor = () => {
        if(props.data.field === 'userId'){
            return
        }
        setIsEditing(true)
    };

    const stopEditing = (boolean, e) => {
        setIsEditing(false)
        if(boolean){
            setValue(value)
        } else {
            setValue(props.value)
        }
    };

    const confirmEdit = async (e) => {
        if(e.keyCode === 27){
            stopEditing(false)
        }
        if(e.keyCode === 13 && !isKvpActive && !isAddingKvp){
            const validation = validateCell(props.data.field, value)
            if(validation.isValid) {
                handleProfileEdit()
            } else {
                dispatch(addToastToStack('danger', validation.msg))
                setValidationFails(true)
                setTimeout(() => {
                    setValidationFails(false)
                }, 500);
                setLoading(false)
            }
        } else if (e.keyCode === 13 && isKvpActive && !isAddingKvp) {
            handleKvpEdit()
        } else if (e.keyCode === 13 && isKvpActive && isAddingKvp) {
            handleAddKvp()
        }
    };

    const handleProfileEdit = () => {
        if(value !== props.data.value){
            dispatch(setIsUserProfileEdited(true))
            let newProfile = userProfileToUpdate
            updateData(value)
            newProfile[props.data.field] = value
            dispatch(setUserProfileToUpdate(userProfileToUpdate))
            stopEditing(true)
        } else {
            stopEditing(false)
        }
    }

    const handleKvpEdit = async () => {
        if(value !== props.data[props.colDef.field]){
            setLoading(true)
            await dispatch(updateKvp({
                key: props.data.field,
                value: value,
                userId: userSelected.data.id
            }))
                .then((res) => {
                    if(res){
                        updateData(value)
                        setValue(value)
                        stopEditing(true)
                        setEditedSuccess(true)
                        setTimeout(() => {
                            setEditedSuccess(false)
                        }, 2000);
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    stopEditing(false)
                    setValue("")
                    setEditedFails(true)
                    setTimeout(() => {
                        setEditedFails(false)
                    }, 2000);
                    setLoading(false)
                }) 
        } else {
            setLoading(false)
            stopEditing(false)
        }
    }

    const handleAddKvp = async () => {
        if(value !== props.data.value){
            await dispatch(setNewKvpValue(value))
            updateData(value)

            if(props.data.field && props.data.value){
                await dispatch(createKvp({
                    key: props.data.field,
                    value: props.data.value,
                    userId: userSelected.data.id,
                }))
                    .then((res) => {
                        if(res){
                            setValue(value)
                            dispatch(setNewKvpKey(""))
                            dispatch(setNewKvpValue(""))
                            stopEditing(true)
                            dispatch(addToastToStack('success', 'New KVP created.'))
                            userKvps.pop()
                            userKvps.push({
                                value: props.data.value,
                                key: props.data.field
                            })
                            dispatch(setUserKvps(userKvps))
                            dispatch(addingKvp(false))
                        }
                    })
                    .catch(async (err) => {
                        dispatch(addToastToStack('danger', 'Fails to add a new Kvp please try again.'))
                        dispatch(setNewKvpKey(""))
                        dispatch(setNewKvpValue(""))
                        stopEditing(false)
                        await removeData()
                        dispatch(addingKvp(false))
                    })
            } else {
                setValue(value)
                stopEditing(true)
            }    
        } else {
            setLoading(false)
            stopEditing(false)
        }
    }

    const updateData = (value) => {
        props.data.value = value
        props.api.onFilterChanged();
        props.api.refreshCells()
    };

    const removeData = async () => {
        setValue("")
        userKvps.pop()
        await dispatch(setUserKvps(userKvps));
    }

    return (
        <div onDoubleClick={(e) => openEditor()} className={ `custom-cell-editor-wrapper ${editedSuccess ? 'success-animation' : ''} ${editedFails ? 'fails-animation' : ''} `}>   
            { isEditing ?
                <div className='custom-cell-editor-container'> 
                    <input 
                        type="text"
                        ref={refInput}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        className={`custom-cell-editor ${validationFails ? 'validation-fails-animation' : ''}`}
                        onBlur={(e) => stopEditing(false, e)}
                        onKeyDown={(e) => confirmEdit(e)}
                        autoFocus
                    />
                    {loading &&
                        <div 
                            className={`spinner-border spinner-border-sm text-secondary spinner-custom-cell`} 
                            role="status"
                        />
                    }
                </div>
                :
                props.value
            }
        </div>
    );
}; 

export default CustomValueEditor;