import axios from "axios";
import environment from "../environments/environment";
import storageService from "./Storage.service";

const { APP_API_URL } = environment;
const KEY_ACCESS_TOKEN = "access_token";
const KEY_CLIENT_ID = 'clientId';
const KEY_DOMAIN = "domain";
const EP_USERS_PATHNAME = "/Users/paginate";
const EP_PROFILE_IMAGE_PATHNAME = "/ProfileImage";
const EP_USER_PROFILE = "/UserProfile";
const EP_CREATE_USER = "/AddUser";
const EP_PROFILE_PATHNAME="/UserProfile";
const EP_KVP_PATHNAME="/CustomUserDataStore";
const EP_GROUPS_PATHNAME = '/Groups';
const EP_GROUPS_USER_PATHNAME = '/GroupUsers';
const EP_ACCOUNT_USER_PATHNAME = '/Users'

export class UsersService {
  getUsers(pageNumber, pageSize) {
    const url = `${APP_API_URL}${EP_USERS_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);
    const clientId = storageService.get(KEY_CLIENT_ID)

    const data = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        [`X-Client-Override`]: `${clientId}`
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data, config, )
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  getUserProfile(userId) {
    const url = `${APP_API_URL}${EP_USER_PROFILE}/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false);
          }
        });
    });
  };

  getProfileImage(userId) {
    const url = `${APP_API_URL}${EP_PROFILE_IMAGE_PATHNAME}/raw/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob'
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error('aca');
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  createUser(user) {
    const url = `${APP_API_URL}${EP_CREATE_USER}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    delete user.userId;

    return new Promise((resolve, reject) => {
      axios
        .post(url, user, config)
        .then((res) => {
          if (200 === res.status) {
            // console.log(res.data);
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  createProfile(profile) {
    const url = `${APP_API_URL}${EP_PROFILE_PATHNAME}/${profile.userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, profile, config)
        .then((res) => {
          if (200 === res.status) {
            // console.log(res.data);
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  updateProfile(profile) {
    const url = `${APP_API_URL}${EP_PROFILE_PATHNAME}/${profile.userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, profile, config)
        .then((res) => {
          if (200 === res.status) {
            // console.log(res.data);
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  getUserKvps = (userId) => {
    const url = `${APP_API_URL}${EP_KVP_PATHNAME}/byUser/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  createKvp = (kvp) => {
    const url = `${APP_API_URL}${EP_KVP_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, kvp, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  updateKvp = (kvp) => {
    const url = `${APP_API_URL}${EP_KVP_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, kvp, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  removeUserFromGroup = (userId, groupId) => {
    const url = `${APP_API_URL}${EP_GROUPS_USER_PATHNAME}/rm/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, groupId, config)
        .then((res) => {
          if (204 === res.status) {
            resolve(res.data);
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  addUserToGroup = (userId, groupId) => {
    const url = `${APP_API_URL}${EP_GROUPS_USER_PATHNAME}/add/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, groupId, config)
        .then((res) => {
          if (204 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  getUserGroups = (userId) => {
    const url = `${APP_API_URL}${EP_GROUPS_USER_PATHNAME}/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  getGroups = () => {
    const url = `${APP_API_URL}${EP_GROUPS_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  updateUserAccount = (data) => {
    const url = `${APP_API_URL}${EP_ACCOUNT_USER_PATHNAME}/${data.id}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url, data, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

  updateProfileImage = (img, userId) => {
    const url = `${APP_API_URL}${EP_PROFILE_IMAGE_PATHNAME}/${userId}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const data = new FormData();
    data.append('file', img);

    return new Promise((resolve, reject) => {
      axios
        .put(url, data, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error()
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(false)
          }
        });
    });
  };

};

const userService = new UsersService();
export default userService;
