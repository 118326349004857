import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import CLIENTUSERSSELECTOR from '../../../../../../Redux/ClientUsers/selectors';
import { useDispatch, useSelector } from 'react-redux';
import './CustomBulkCellEditor.css';
import { setUserBulkFormattedData } from '../../../../../../Redux/ClientUsers/actions';

const BulkCellsEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value || "");
    const bulkFormattedData = useSelector(CLIENTUSERSSELECTOR.BULKFORMATTEDDATA);
    const dispatch = useDispatch()
    const refInput = useRef(null);
 
    useEffect(() => {
        // focus on the input
        setTimeout(() => refInput.current.focus());
    }, []);
 
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                // this simple editor doubles any value entered into the input
                return value;
            },
 
            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false;
            },
 
            // Gets called once when editing is finished (eg if enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                if(value !== props.value){
                    props.node.setSelected(false)
                }
                
                bulkFormattedData[props.rowIndex][props.colDef.field] = value
                dispatch(setUserBulkFormattedData(bulkFormattedData))
                // our editor will reject any value greater than 1000
                return false
            }
        };
    });
 
    return (
        <input 
            type="text"
            ref={refInput}
            value={value}
            onChange={event => setValue(event.target.value)}
            className={`custom-cell-editor`}
        />
    );
 });

export default BulkCellsEditor;