import { AgGridReact } from "ag-grid-react";
import { MDBContainer } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./EventsDataGrid.css";

const EventsDataGrid = () => {
  const eventSelected = useSelector(
    (state) => state.clientEvents.eventSelected
  );
  const clientCategories = useSelector(
    (state) => state.clientEvents.categories
  );
  const dispatch = useDispatch();
  const [eventGrid, setEventGrid] = useState(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [defaultColDef, setDefaultColDef] = useState({
    sortable: true,
    resizable: true,
    filter: true,
    // floatingFilter: true,
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Field",
      field: "field",
      suppressSizeToFit: true,
      flex: 1,
    },
    {
      headerName: "Value",
      field: "value",
      suppressSizeToFit: true,
      editable: true,
      flex: 1,
      valueGetter: (params) => {
        if (params.data.value === false) {
          return "false";
        } else {
          return params.data.value;
        }
      },
    },
  ]);

  const fields = [
    "name",
    "description",
    "duration",
    "startTime",
    "preRoll",
    "disablePreRoll",
    "eventFeaturesZoomURL",
    "categories",
    "eventFeaturesCustomPlayerPageOverrideSnippetId",
    "eventFeaturesQuestionAndAnswer",
    "eventFeaturesRegistration",
    "eventFeaturesVoting",
    "thumbnail",
    "autoplay",
  ];

  const EventToEventGrid = (event) => {
    const keys = Object.keys(event);
    let res = [];
    keys.forEach((fieldName) => {
      if (fields.includes(fieldName)) {
        res.push({
          field: fieldName,
          value: eventSelected[fieldName],
        });
      }
    });
    return res;
  };

  useEffect(() => {
    if (eventSelected) {
      const eventData = EventToEventGrid(eventSelected);
      setEventGrid(eventData);
    }
  }, [eventSelected]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  return (
    <MDBContainer fluid>
      {eventSelected && (
        <div className="EventGrid">
          <div className="event-grid-container ag-theme-alpine">
            <AgGridReact
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={eventGrid}
            ></AgGridReact>
          </div>
        </div>
      )}
    </MDBContainer>
  );
};

export default EventsDataGrid;
