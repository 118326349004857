import {
    ADD_TOAST,
    REMOVE_TOAST,
} from '../actions';
  

export const addToastToStack = (type, msg) => {
    return (dispatch, getState) => {
        let toastStack = getState().toast.stack
        let newId =  getState().toast.id + 1
        let toast = {
            type: type,
            msg: msg,
            id: newId
        }
        toastStack.push(toast)

        dispatch(addToast(toastStack, newId))
    }
};

export const addToast = (stack, id) => {
    return {
        type: ADD_TOAST,
        stack: stack,
        id: id,
    };
};

export const removeToast = (id) => {
    return {
        type: REMOVE_TOAST,
        id: id
    };
};