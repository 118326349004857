import { REMOVE_CLIENT_STATE, SET_CLIENT_ID_REQUESTED, SET_USER_CLIENTS } from "../actions";
import clientService from '../../Services/Client.service';
import { formatClientToSelect } from "../../Helpers/ClientsHelper";

export const fetchClients = () => {
    return (dispatch) => {
        return new Promise ((resolve, reject) => {
            clientService.getClients()
                .then((data) => {
                    dispatch(setUserClients(formatClientToSelect(data)))
                    resolve(true)
                })
                .catch((err) => {
                    /** ver que mostrar de error **/
                    reject(false)
                })
        })
            
    }
}

export const setUserClients = (clients) => ({
    type: SET_USER_CLIENTS,
    payload: clients
})

export const setClientIdRequested = (clientId) => ({
    type: SET_CLIENT_ID_REQUESTED,
    payload: clientId
})

export const removeClientState = (clientId) => ({
    type: REMOVE_CLIENT_STATE,
    payload: clientId
})
