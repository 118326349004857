import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { refreshToken } from "../Redux/Auth/actions";
import { PATHS } from '../Constants/Paths';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  useEffect(() => {
    if(!isAuthenticated){
      dispatch(refreshToken())
        .then((res) => {
          setLoading(false)
        })
        .catch((err) =>{
          setLoading(false)
          history.push(PATHS.login)
        })
    }
  }, [isAuthenticated]);

  if( loading ) {
    return null
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default Auth;
