import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserAccount } from '../../../../../Redux/ClientUsers/actions';
import './CustomCellEditor.css';
import {validateCell} from '../../../../../Helpers/UsersHelper';
import {addToastToStack} from '../../../../../Redux/Toast/actions';

const CustomCellEditor = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editedSuccess, setEditedSuccess] = useState(false);
    const [editedFails, setEditedFails] = useState(false);
    const [validationFails, setValidationFails] = useState(false);
    const [value, setValue] = useState(props.value);
    const dispatch = useDispatch();
    const refInput = useRef();
    
    const openEditor = () => {
        setIsEditing(true)
    };

    const stopEditing = (boolean) => {
        setIsEditing(false)
        if(boolean){
            setValue(value)
        } else {
            setValue(props.value)
        }
    };

    const confirmEdit = async (e) => {
        if(e.keyCode === 27){
            stopEditing(false)
        }
        if(e.keyCode === 13){
            setLoading(true)
            const validation = validateCell(props.colDef.field, value)
            if(validation.isValid){
                await handleAccountEdit()
            } else {
                dispatch(addToastToStack('danger', validation.msg))
                setValidationFails(true)
                setTimeout(() => {
                    setValidationFails(false)
                }, 500);
                setLoading(false)
            }
        }
    };

    const handleAccountEdit = async () => {
        if(value !== props.data[props.colDef.field]){
            let accountUpdated
            if(props.colDef.field === 'firstName'){
                accountUpdated = {
                    id: props.data.id,
                    surname: props.data.surname,
                    firstName: value === "" ? null : value,
                }
            } else {
                accountUpdated = {
                    id: props.data.id,
                    surname: value === "" ? null : value,
                    firstName: props.data.firstName,
                }
            }
            
            await dispatch(updateUserAccount(accountUpdated))
                .then((res) => {
                    updateData(value)
                    setLoading(false)
                    setValue(value)
                    setEditedSuccess(true)
                    stopEditing(true)
                    setTimeout(() => {
                        setEditedSuccess(false)
                    }, 2000);
                })
                .catch((res) => {
                    setLoading(false)
                    setEditedFails(true)
                    stopEditing(false)
                    setValue(props.value)
                    setTimeout(() => {
                        setEditedFails(false)
                    }, 2000);
                })                
        } else {
            setLoading(false)
            stopEditing(false)
        }
    }

    const updateData = (value) => {
        props.data[props.colDef.field] = value
        props.api.onFilterChanged();
        props.api.refreshCells()
    };

    return (
        <div onDoubleClick={(e) => openEditor()} className={ `custom-cell-editor-wrapper ${editedSuccess ? 'success-animation' : ''} ${editedFails ? 'fails-animation' : ''} `}>   
            { isEditing ?
                <div className='custom-cell-editor-container'> 
                    <input 
                        type="text"
                        ref={refInput}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        className={`custom-cell-editor ${validationFails ? 'validation-fails-animation' : ''}`}
                        onBlur={() => stopEditing(false)}
                        onKeyDown={(e) => confirmEdit(e)}
                        autoFocus
                    />
                    {loading &&
                        <div 
                            className={`spinner-border spinner-border-sm text-secondary spinner-custom-cell`} 
                            role="status"
                        />
                    }
                </div>
                :
                props.value
            }
        </div>
    );
}; 

export default CustomCellEditor;