import {
    REMOVE_TOAST,
    ADD_TOAST,
} from '../actions';
    
  const INIT_STATE = {
    stack: [],
    id: 0,
  };
    
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_TOAST:
            return {
                ...state,
                stack: action.stack,
                id: action.id
            }
        
        case REMOVE_TOAST:
            return {
                ...state,
                stack: state.stack.filter(toast => toast.id !== action.id)
            }
      
        default:
            return { 
            ...state 
            };
    }
  };
    
  export default reducer;