import { MDBContainer } from "mdbreact";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../Redux/Tabs/actions";
import UserBulkUpdate from "./UserBulkUpdate/UserBulkUpdate";
import UserManagement from "./UserManagement/UserManagement";
import "./UsersModule.css";

const UsersModule = () => {
  const activeTab = useSelector((state) => state.tabs.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(1));
    };
  }, []);

  return (
    <MDBContainer fluid>
      {activeTab === 1 && <UserManagement />}
      {activeTab === 2 && <UserBulkUpdate />}
    </MDBContainer>
  );
};

export default UsersModule;
