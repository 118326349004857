import axios from "axios";
import environment from "../environments/environment";
import storageService from "./Storage.service";

const { APP_API_URL } = environment;
const CLIENTS_PATHNAME = "/Clients";
const KEY_ACCESS_TOKEN = "access_token";

export class ClientService {
  getClients() {
    const url = `${APP_API_URL}${CLIENTS_PATHNAME}`;
    const token = storageService.get(KEY_ACCESS_TOKEN);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          if (200 === res.status) {
            resolve(res.data);
          } else {
            throw new Error();
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }
}

const clientService = new ClientService();
export default clientService;
