import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LicenseManager } from "ag-grid-enterprise";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import './App.css';
import Auth from "./auth/Auth";
import AuthGuard from "./auth/AuthGuard";
import { PATHS } from "./Constants/Paths";
import AppLayout from "./Layout/AppLayout";
import { Routes } from "./Routes";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_EKEY);

const App = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
    <div className="app">
      <BrowserRouter>
        <Auth>
          <AppLayout>
            <AuthGuard>
              <Switch>
                {Routes.map((route, index) => {
                  return (
                    <Route exact path={route.path} component={route.component} key={index} />
                  )
                })}
                <Redirect to={isAuthenticated ? `${PATHS.dashboard}` : `${PATHS.login}`} />
              </Switch>
            </AuthGuard>
          </AppLayout>
        </Auth>
      </BrowserRouter>
    </div>
  );
}

export default App;
