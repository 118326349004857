import React from 'react';
import { useDispatch } from 'react-redux';
import { MDBNotification } from 'mdbreact';
import { removeToast } from '../../Redux/Toast/actions';


const Toast = ({toast}) => {
    const dispatch = useDispatch()

    const closeToast = () => {
        dispatch(removeToast(toast.id))
    }

    const getColor = () => {
        switch (toast.type) {
            case 'success':
                    return 'success-color'
            case 'warning':
                    return 'warning-color'
            case 'danger':
                    return 'danger-color'
            case 'info':
                    return 'secondary-color'
            default:
                break;
        }
    }

    return(
        <MDBNotification
            autohide={3000}
            bodyClassName="p-3 font-weight-bold white-text"
            className={getColor()}
            closeClassName="blue-grey-text"
            fade
            icon="bell"
            iconClassName="blue-grey-text"
            message={toast.msg}
            show
            titleClassName="d-none"
            onClick={() => closeToast()}
        />
    )
};

export default Toast;