import {
    SET_USER_CREDENTIALS,
    SET_USER_ROLE,
    SET_USER_CLIENTID,
    SET_TOKEN,
    REMOVE_USER_CREDENTIALS,
} from '../actions';
  

export const setUserCredentials = (credentials) => {
    return {
        type: SET_USER_CREDENTIALS,
        payload: credentials,
    };
};

export const setUserRoles = (userRoles) => {
    return {
        type: SET_USER_ROLE,
        payload: userRoles,
    };
};
  
export const setUserClientId = (clientId) => {
    return {
        type: SET_USER_CLIENTID,
        payload: clientId,
    };
};

export const setUserToken = (token) => {
    return {
        type: SET_TOKEN,
        payload: token,
    };
};

export const removeUserCredentials = () => {
    return {
        type: REMOVE_USER_CREDENTIALS,
    };
};