import { MDBBtn, MDBContainer } from "mdbreact";
import React from "react";
import { useSelector } from "react-redux";
import "./EventManagement.css";
import EventsDataGrid from "./EventsDataGrid/EventsDataGrid";
import EventsGrid from "./EventsGrid/EventsGrid";

const onCreateEvent = () => {};

const onUpdateEvent = () => {};

const EventManagement = () => {
  const eventSelected = useSelector(
    (state) => state.clientEvents.eventSelected
  );

  return (
    <MDBContainer fluid>
      <div className="EventManagement">
        <div className="events-grid">
          <EventsGrid></EventsGrid>
        </div>
        <div className="events-button-container">
          <MDBBtn
            color="secondary"
            type="button"
            onClick={onCreateEvent}
            disabled
          >
            Create Event
          </MDBBtn>
        </div>
        {!eventSelected && (
          <div className="event-data">
            <div className="noevent-container">
              <span>Please Select Event</span>
            </div>
          </div>
        )}
        {eventSelected && (
          <div className="event-data-grid">
            <EventsDataGrid></EventsDataGrid>
          </div>
        )}
        {eventSelected && (
          <div className="event-data-button-container">
            <MDBBtn
              color="secondary"
              type="button"
              onClick={onUpdateEvent}
              disabled
            >
              Update Event
            </MDBBtn>
          </div>
        )}
      </div>
    </MDBContainer>
  );
};

export default EventManagement;
