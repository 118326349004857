import { MDBContainer } from "mdbreact";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../Redux/Tabs/actions";
import EventBulkUpdate from "./EventBulkUpdate/EventBulkUpdate";
import EventManagement from "./EventManagement/EventManagement";
import "./EventsModule.css";

const EventsModule = () => {
  const activeTab = useSelector((state) => state.tabs.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(1));
    };
  }, []);

  return (
    <MDBContainer fluid className="EventsModule">
      {activeTab === 1 && <EventManagement />}
      {activeTab === 2 && <EventBulkUpdate />}
    </MDBContainer>
  );
};

export default EventsModule;
