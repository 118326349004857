import {
    SET_USER_ROLE,
    SET_USER_CLIENTID,
    SET_TOKEN,
    SET_USER_CREDENTIALS,
    REMOVE_USER_CREDENTIALS,
} from '../actions';
    
export const INIT_STATE = {
    userRoles: [],
    userClientId: '',
    userToken: '',
  };
    
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USER_CREDENTIALS:
            return {
                ...state,
                userRoles: action.payload.Roles,
                userClientId: action.payload.ClientId,
                userToken: action.payload.AccessToken
            }
        case SET_USER_ROLE:
            return { 
                ...state, 
                userRoles: action.payload 
            };
        case SET_USER_CLIENTID:
            return { 
                ...state, 
                userClientId: action.payload 
            };
        case SET_TOKEN:
            return { 
                ...state, 
                userToken: action.payload 
            };
        case REMOVE_USER_CREDENTIALS:
            return { 
                ...state,
                userRoles: [],
                userClientId: '',
                userToken: '', 
            };
        default:
            return { 
            ...state 
            };
    }
  };
    
  export default reducer;