import { HeaderRootComp } from 'ag-grid-community';
import usersService from '../../Services/Users.service';
import {
    SET_CLIENT_USERS,
    SET_FETCHING_USERS,
    SET_FETCHING_USER_PROFILE,
    SET_USER_BULK_COLUMN_FIELD_MAP,
    SET_USER_BULK_DATA,
    SET_USER_BULK_FORMATTED_COLUMNS,
    SET_USER_BULK_FORMATTED_DATA,
    SET_USER_BULK_HAS_HEADERS,
    SET_USER_BULK_HEADER_FIELDS,
    SET_USER_BULK_USERS,
    SET_USER_PROFILE,
    SET_USER_PROFILE_IMAGE,
    SET_USER_SELECTED,
    SET_USERS_BULK_COLUMN_FIELD_SELECT,
    SET_USERS_COLUMNS_SELECTED,
    SET_USERS_BULK_DATA_SELECTED,
    REMOVE_USERS_STATE,
    SET_USER_KVPS,
    SET_USERS_GROUPS,
    SET_USER_BEING_PUSHED,
    SET_IS_GROUP_COLUMN,
    SET_USER_PROFILE_TO_UPDATE,
    SET_KVP_ACTIVE,
    SET_IS_ADDING_KVP,
    SET_NEW_KVP_KEY,
    SET_NEW_KVP_VALUE,
    SET_GROUP_COLUMN_ORIGINAL_DATA,
    SET_NEW_PROFILE_IMAGE_FILE,
    SET_FETCHING_USER_PROFILE_IMAGE,
    SET_IS_USER_PROFILE_EDITED
} from '../actions';
import { logout } from '../Auth/actions';
import { setClientIdRequested } from '../Clients/actions';

export const getClientUsers = (pageNumber, pageSize, client) => {
    return (dispatch) => {
        dispatch(setFetchingUsers(true))
        dispatch(setClientUsers([]))

        return new Promise((resolve, reject) => {
            usersService.getUsers(pageNumber, pageSize, client)
                .then((data) => {
                    const dataWithoutAnonymous = data.filter((user) => !user.email.includes('anonymous@'))
                    dispatch(setClientUsers(dataWithoutAnonymous))
                    dispatch(setFetchingUsers(false))
                    dispatch(setClientIdRequested(client))
                    resolve(dataWithoutAnonymous)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
};

export const getUserProfile = (userId) => {
    return (dispatch) => {
        dispatch(setFetchingUserProfile(true))

        return new Promise((resolve, reject) => {
            usersService.getUserProfile(userId)
                .then((data) => {
                    dispatch(setUserProfile(data))
                    dispatch(setUserProfileToUpdate(data))
                    dispatch(setFetchingUserProfile(false))
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    dispatch(setFetchingUserProfile(false))
                    reject(false)
                })
        })
    }
};

export const getUserProfileToCompare = (userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.getUserProfile(userId)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
};

export const getUserProfileImage = (userId) => {
    return (dispatch) => {
        dispatch(setFetchingUserProfileImage(true))

        return new Promise((resolve, reject) => {
            usersService.getProfileImage(userId)
                .then((data) => {
                    try {
                        const imgUrl = URL.createObjectURL(data)
                        dispatch(setUserProfileImage(imgUrl))
                    } catch (error) {
                        
                    }
                    dispatch(setFetchingUserProfileImage(false))
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    dispatch(setFetchingUserProfileImage(false))
                    reject(false)
                })
        })
    }
};

export const updateProfileImage = (data, userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.updateProfileImage(data, userId)
                .then((data) => {
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
};

export const getUserKvps = (userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.getUserKvps(userId)
                .then((data) => {
                    dispatch(setUserKvps(data))
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
};

export const createUser = (user) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.createUser(user)
                .then((data) => {
                    resolve(data.id)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const createProfile = (profile) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.createProfile(profile)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const updateProfile = (profile) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.updateProfile(profile)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const createKvp = (kvp) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.createKvp(kvp)
                .then((data) => {
                    resolve(data.id)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const updateKvp = (kvp) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.updateKvp(kvp)
                .then((data) => {
                    resolve(data.id)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const addUserToGroup = (userId, groupId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.addUserToGroup(userId, groupId)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const removeUserFromGroup = (userId, groupId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.removeUserFromGroup(userId, groupId)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const getUserGroups = (userId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.getUserGroups(userId)
                .then((data) => {
                    const groupsIds = data.map((el) => {
                        return el.id
                    })
                    resolve(groupsIds)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const getGroups = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.getGroups()
                .then((data) => {
                    let groups = data.map((group) => {
                        return {
                            label: group.name,
                            value: group.id
                        }
                    })
                    dispatch(setGroups(groups))
                    resolve(true)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        // console.log('get groups', err.status)
                    }
                    reject(false)
                })
        })
    }
};


export const resetUserBulkHeaderFields = (userBulkHeaderFields) => {
    return(dispatch) => {
        const resetParam = userBulkHeaderFields.map((header) => {
            header.disabled = false
            return header
        })
        dispatch(setUserBulkHeaderFields(resetParam))
    }
}

export const setSelectionColumn = () => {
    return(dispatch, getState) => {
        const state = getState()
        const bulkFormattedColumns = state.clientUsers.bulkFormattedColumns 

        bulkFormattedColumns.unshift(
            {
                headerName: "",
                field: "selection",
                width: 50,
                sortable: false,
                resizable: false,
                filter: false,
                checkboxSelection: false,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                editable: false,
                headerComponentParams: {show: false},
                cellRenderer: "customLoadingCell",
            },
            {
                headerName: "User Id",
                field: "userId",
                sortable: true,
                filter: true,
                editable: false,
                headerComponentParams: {show: false}
            }
          )
        
        dispatch(setUsersColumnsSelected(true))
        dispatch(setUserBulkFormattedColumns(bulkFormattedColumns))
    }
}

export const removeSelectionColumn = () => {
    return async (dispatch, getState) => {
        const state = getState()
        const bulkFormattedColumns = state.clientUsers.bulkFormattedColumns 
        const usersColumnsSelected = state.clientUsers.usersColumnsSelected
        let newHeaders = bulkFormattedColumns

        if(usersColumnsSelected){
            newHeaders = bulkFormattedColumns.filter((header) => header.field !== 'selection' && header.field !== 'userId')
        }
        
        await dispatch(setUsersColumnsSelected(false))
        await dispatch(setUserBulkFormattedColumns(newHeaders))
    }
}

export const setGroupsColumn = (column) => {
    return(dispatch, getState) => {
        const state = getState()
        const bulkFormattedColumns = state.clientUsers.bulkFormattedColumns 

        const newHeaders = bulkFormattedColumns.map((header) => {
            if(header.field === column){
                header.cellRenderer = "customGroupCell"
                header.editable = false
                header.autoHeight = true
                header.cellEditor = null
            }
            return header
        })       

        dispatch(setGroupColumn(column))
        dispatch(setUserBulkFormattedColumns(newHeaders))
    }
}

export const removeGroupsColumn = (column) => {
    return async (dispatch, getState) => {
        const state = getState()
        const bulkFormattedColumns = state.clientUsers.bulkFormattedColumns 
        const usersColumnsSelected = state.clientUsers.usersColumnsSelected
        let newHeaders = bulkFormattedColumns

        if(usersColumnsSelected && column){
            newHeaders = bulkFormattedColumns.map((header) => {
                if(header.field === column){
                    delete header.cellRenderer
                    delete header.editable
                }
                return header
            })       
        } else if (usersColumnsSelected){
            newHeaders = bulkFormattedColumns.map((header) => {
                if(!(header.field === "selection") && !(header.field === "userId")){
                    delete header.cellRenderer
                    delete header.editable
                }
                return header
            })       
        } else if (!usersColumnsSelected){
            newHeaders = bulkFormattedColumns.map((header) => {
                if(header.field === column){
                    delete header.cellRenderer
                    delete header.editable
                }
                return header
            })   
        }
        
        await dispatch(setGroupColumn(null))
        await dispatch(setUserBulkFormattedColumns(newHeaders))
    }
}

export const updateUserAccount = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            usersService.updateUserAccount(data)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    if (err.status === 401) {
                        dispatch(logout())
                    }
                    reject(false)
                })
        })
    }
}

export const saveBulkData = (dataToSave) => {
    return (dispatch, getState) => {
        const state = getState()
        
    }
}

export const setFetchingUsers = (boolean) => ({
    type: SET_FETCHING_USERS,
    payload: boolean
});

export const setClientUsers = (users) => ({
    type: SET_CLIENT_USERS,
    payload: users
});

export const setUserSelected = (user) => ({
    type: SET_USER_SELECTED,
    payload: user
});

export const setFetchingUserProfile = (boolean) => ({
    type: SET_FETCHING_USER_PROFILE,
    payload: boolean
});

export const setFetchingUserProfileImage = (boolean) => ({
    type: SET_FETCHING_USER_PROFILE_IMAGE,
    payload: boolean
});

export const setUserProfile = (userProfile) => ({
    type: SET_USER_PROFILE,
    payload: userProfile
});

export const setUserProfileToUpdate = (userProfile) => ({
    type: SET_USER_PROFILE_TO_UPDATE,
    payload: userProfile
});

export const setUserProfileImage = (image) => ({
    type: SET_USER_PROFILE_IMAGE,
    payload: image
});

export const setUserKvps = (kvps) => ({
    type: SET_USER_KVPS,
    payload: kvps
});

export const setUserBulkHasHeaders = (boolean) => ({
    type: SET_USER_BULK_HAS_HEADERS,
    payload: boolean
});

export const setUserBulkHeaderFields = (data) => ({
    type: SET_USER_BULK_HEADER_FIELDS,
    payload: data
});

export const setUserBulkData = (data) => ({
    type: SET_USER_BULK_DATA,
    payload: data
});

export const setUserBulkFormattedColumns = (data) => ({
    type: SET_USER_BULK_FORMATTED_COLUMNS,
    payload: data
});

export const setUserBulkFormattedData = (data) => ({
    type: SET_USER_BULK_FORMATTED_DATA,
    payload: data
});

export const setUserBulkColumnFieldMap = (data) => ({
    type: SET_USER_BULK_COLUMN_FIELD_MAP,
    payload: data
});

export const setUserBulkUsers = (data) => ({
    type: SET_USER_BULK_USERS,
    payload: data
});

export const setUsersBulkColumnFieldSelect = (data) => ({
    type: SET_USERS_BULK_COLUMN_FIELD_SELECT,
    payload: data
})

export const setUsersColumnsSelected = (boolean) => ({
    type: SET_USERS_COLUMNS_SELECTED,
    payload: boolean
})

export const setUsersBulkDataSelected = (data) => ({
    type: SET_USERS_BULK_DATA_SELECTED,
    payload: data
})

export const removeUserState = () => ({
    type: REMOVE_USERS_STATE,
})

export const setGroups = (data) => ({
    type: SET_USERS_GROUPS,
    payload: data
})

export const setUserBeingPushed = (userEmail) => ({
    type: SET_USER_BEING_PUSHED,
    payload: userEmail
})

export const setGroupColumn = (boolean) => ({
    type: SET_IS_GROUP_COLUMN,
    payload: boolean
})

export const setKvpActive = (boolean) => ({
    type: SET_KVP_ACTIVE,
    payload: boolean
})

export const addingKvp = (boolean) =>  ({
    type: SET_IS_ADDING_KVP,
    payload: boolean
})

export const setNewKvpKey = (value) => ({
    type: SET_NEW_KVP_KEY,
    payload: value
})

export const setNewKvpValue = (value) => ({
    type: SET_NEW_KVP_VALUE,
    payload: value
})

export const setGroupsColumnOriginalData = (value) => ({
    type: SET_GROUP_COLUMN_ORIGINAL_DATA,
    payload: value
})

export const setNewProfileImageFile = (file) => ({
    type: SET_NEW_PROFILE_IMAGE_FILE,
    payload: file
})

export const setIsUserProfileEdited = (boolean) => ({
    type: SET_IS_USER_PROFILE_EDITED,
    payload: boolean
})