import React from 'react';

const NoRowsOverlay = (props) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{height: '100%'}}
    >
        {props.loadingMessage}
    </div>
  );
};

export default NoRowsOverlay;