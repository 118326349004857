import { MDBContainer } from "mdbreact";
import React from "react";
import Header from "../Components/Header/Header";
import ToastContainer from "../Components/Toast/ToastContainer";
import "./AppLayout.css";

const AppLayout = (props) => {
  return (
    <div id="app-container" className="AppLayout">
      <Header />
      <main>
        <MDBContainer fluid>{props.children}</MDBContainer>
        <ToastContainer />
      </main>
    </div>
  );
};

export default AppLayout;
