import { createSelector } from 'reselect'
import { get } from 'lodash'
import { INIT_STATE } from './reducer'

const key = 'clients'

const selectClients = state => get(state, key, INIT_STATE)

const selectClienIdRequested = createSelector(
    selectClients, state => get(state, 'clientIdRequested', {})
)

export default {
    PREVIUOSSELECTED: selectClienIdRequested,
} 