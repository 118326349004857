import axios from "axios";
import environment from "../environments/environment";
import storageService from "./Storage.service";

const { APP_API_URL } = environment;
const TOKEN_PATHNAME = "/token";
const KEY_ACCESS_TOKEN = "access_token";
const KEY_REFRESH_TOKEN = "refresh_token";
const KEY_CLIENT_ID = 'clientId';
const KEY_DOMAIN = "domain";

export class AuthService {
  signIn(email, password, domain) {
    const url = `${APP_API_URL}${TOKEN_PATHNAME}`;

    const data = {
      email: email,
      password: password,
      grantType: "password",
    };
    const config = {
      headers: {
        "X-InfernoCore-Domain": `${domain}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((res) => {
          if (200 === res.status) {
            storageService.set(KEY_ACCESS_TOKEN, res.data.AccessToken);
            storageService.set(KEY_DOMAIN, domain);
            storageService.set(KEY_REFRESH_TOKEN, res.data.RefreshToken);
            storageService.set(KEY_CLIENT_ID, res.data.ClientId);
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  refreshToken() {
    const url = `${APP_API_URL}${TOKEN_PATHNAME}`;
    const token = storageService.get(KEY_REFRESH_TOKEN);

    const data = {
      refreshToken: token,
      grantType: "refresh_token",
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((res) => {
          // console.log("refresh", res);
          if (200 === res.status) {
            storageService.set(KEY_ACCESS_TOKEN, res.data.AccessToken);
            storageService.set(KEY_REFRESH_TOKEN, res.data.RefreshToken);
            resolve(res.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          }
        });
    });
  }

  logoutUser() {
    return new Promise((resolve, reject) => {
      storageService.clear();
      resolve(true);
    });
  }
}

const authService = new AuthService();
export default authService;
