import {
  REMOVE_CLIENT_STATE,
  SET_CLIENT_ID_REQUESTED,
  SET_USER_CLIENTS
  } from '../actions';
  
  export const INIT_STATE = {
    clientsList: [],
    clientIdRequested: null
  };
  
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_USER_CLIENTS:
        return {
          ...state,
          clientsList: action.payload
        };

      case SET_CLIENT_ID_REQUESTED:
        return { 
            ...state, 
            clientIdRequested: action.payload 
        };
      
      case REMOVE_CLIENT_STATE:
        return { 
            ...state, 
            clientsList: [],
            clientIdRequested: null
        };
  
      default:
        return {
          ...state
        };
    }
  };
  
  export default reducer;