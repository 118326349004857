import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  setEventsBulkColumnFieldMap,
  setEventsBulkColumnFieldSelect,
  setEventsBulkHeaderFields,
} from "../../../../../../Redux/ClientEvents/actions";
import "./CustomColumnEventsHeader.css";

const CustomColumnEventsHeader = (props) => {
  const [selectedOption, setSelectedOption] = useState();
  //const [ascSort, setAscSort] = useState("inactive");
  //const [descSort, setDescSort] = useState("inactive");
  //const [noSort, setNoSort] = useState("inactive");
  //const refButton = useRef(null);
  const dispatch = useDispatch();
  const bulkHeaderFields = useSelector(
    (state) => state.clientEvents.bulkHeaderFields
  );
  const bulkColumnFieldMap = useSelector(
    (state) => state.clientEvents.bulkColumnFieldMap
  );

  // const onMenuClicked = () => {
  //   props.showColumnMenu(refButton.current);
  // };

  // const onSortChanged = () => {
  //   setAscSort(props.column.isSortAscending() ? "active" : "inactive");
  //   setDescSort(props.column.isSortDescending() ? "active" : "inactive");
  //   setNoSort(
  //     !props.column.isSortAscending() && !props.column.isSortDescending()
  //       ? "active"
  //       : "inactive"
  //   );
  // };

  // const onSortRequested = (order, event) => {
  //   props.setSort(order, event.shiftKey);
  // };

  useEffect(() => {
    props.api.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (bulkColumnFieldMap) {
      const headerSelected = bulkHeaderFields.filter(
        (header) =>
          header.value === bulkColumnFieldMap[props.column.colDef.field]
      );
      setSelectedOption(...headerSelected);
    }
  }, [bulkColumnFieldMap]);

  // const menu = (
  //   <div
  //     ref={refButton}
  //     className="customHeaderMenuButton"
  //     onClick={() => onMenuClicked()}
  //   >
  //     <i className={`fa ${props.menuIcon}`}></i>
  //   </div>
  // );

  // const sort = (
  //   <div style={{ display: "inline-block" }}>
  //     <div
  //       onClick={(event) => onSortRequested("asc", event)}
  //       onTouchEnd={(event) => onSortRequested("asc", event)}
  //       className={`customSortDownLabel ${ascSort}`}
  //     >
  //       <i class="fa fa-long-arrow-alt-down"></i>
  //     </div>
  //     <div
  //       onClick={(event) => onSortRequested("desc", event)}
  //       onTouchEnd={(event) => onSortRequested("desc", event)}
  //       className={`customSortUpLabel ${descSort}`}
  //     >
  //       <i class="fa fa-long-arrow-alt-up"></i>
  //     </div>
  //     <div
  //       onClick={(event) => onSortRequested("", event)}
  //       onTouchEnd={(event) => onSortRequested("", event)}
  //       className={`customSortRemoveLabel ${noSort}`}
  //     >
  //       <i class="fa fa-times"></i>
  //     </div>
  //   </div>
  // );

  const handleChange = (value) => {
    const previousValue = selectedOption?.value;
    setSelectedOption(value);
    const selectedValue = value.value;

    const headerUpdated = bulkHeaderFields.map((header) => {
      if (header.value === selectedValue && selectedValue !== "select") {
        header.disabled = true;
        return header;
      } else if (header.value === previousValue) {
        header.disabled = false;
        return header;
      } else {
        return header;
      }
    });
    dispatch(setEventsBulkHeaderFields(headerUpdated));

    const columnName = props.column.colDef.field;
    const columnFieldMap = bulkColumnFieldMap ? bulkColumnFieldMap : {};

    // Value prev selected validation
    const values = Object.values(columnFieldMap);
    if (values && values.includes(selectedValue)) {
      value.value = "select";
      delete columnFieldMap[columnName];
    } else {
      const isFieldSelected = "select" !== selectedValue;

      if (isFieldSelected) {
        columnFieldMap[columnName] = selectedValue;
      } else {
        delete columnFieldMap[columnName];
      }
    }

    dispatch(setEventsBulkColumnFieldMap(columnFieldMap));
    dispatch(setEventsBulkColumnFieldSelect(new Date()));

    props.api.refreshCells();
  };

  return (
    <div className={`${props.show ? "" : "d-none"}`}>
      {/* {props.enableMenu && menu} */}
      <div className="customHeaderLabel">{props.displayName}</div>
      {bulkHeaderFields && 0 < bulkHeaderFields.length && (
        <Select
          className="ag-grid-react-select-container mt-1"
          classNamePrefix="ag-grid-react-select"
          options={bulkHeaderFields.filter(
            (header) => header.disabled === false
          )}
          onChange={(value) => handleChange(value)}
          menuPortalTarget={document.body}
          value={selectedOption}
        />
      )}
      {/* {props.enableSorting && sort} */}
    </div>
  );
};

export default CustomColumnEventsHeader;
