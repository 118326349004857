import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer } from "mdbreact";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  authenticate,
  clearAuthenticateErrors
} from "../../Redux/Auth/actions";
import "./Login.css";

const { REACT_APP_VERSION } = process.env;

const Login = () => {
  const [fields, setFields] = useState({
    domain: "jolokia.inferno.jolokia.com",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    domain: "",
    email: "",
    password: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const authenticateError = useSelector(
    (state) => state.auth.authenticateError
  );

  const signIn = (e) => {
    e.preventDefault();
    if (isAuthenticating) {
      return;
    }
    setErrors({
      domain: "",
      email: "",
      password: "",
    });
    dispatch(clearAuthenticateErrors());

    const areFieldsValid = validateFields(fields);

    if (areFieldsValid) {
      dispatch(authenticate(fields.email, fields.password, fields.domain))
        .then((res) => {
          history.push("/dashboard");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const inputHandler = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const validateFields = ({ email, domain, password }) => {
    let errors = {};

    if (!email || email.trim().length === 0) {
      errors.email = "This field is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Please insert a valid email.";
    }

    if (!domain || domain.trim().length === 0) {
      errors.domain = "This field is required.";
    }

    if (!password || password.trim().length === 0) {
      errors.password = "This field is required.";
    }

    if (errors.email || errors.domain || errors.password) {
      setErrors({
        email: errors.email,
        domain: errors.domain,
        password: errors.password,
      });
      return false;
    } else {
      return true;
    }
  };

  return (
    <MDBContainer className="Login d-flex align-items-center justify-content-center">
      <MDBCol lg="8">
        <MDBCard>
          <MDBCardBody>
            <form onSubmit={(e) => signIn(e)}>
              <p className="h3 font-weight-light text-center mb-3">Sign in</p>
              {authenticateError.msg && (
                <p className="red-text m-0 text-align-center text-center">
                  {authenticateError.msg}
                </p>
              )}
              <div className="mb-4">
                <label htmlFor="domain">Domain</label>
                <input
                  type="text"
                  className={`form-control ${
                    authenticateError.msg || errors.domain ? "error" : ""
                  }`}
                  value={fields.domain}
                  name="domain"
                  onChange={(e) => inputHandler(e)}
                />
                <span className="red-text"> {errors.domain} </span>
              </div>
              <div className="mb-4">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className={`form-control ${
                    authenticateError.msg || errors.email ? "error" : ""
                  }`}
                  value={fields.email}
                  name="email"
                  onChange={(e) => inputHandler(e)}
                />
                <span className="red-text"> {errors.email} </span>
              </div>
              <div className="mb-4">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className={`form-control ${
                    authenticateError.msg || errors.password ? "error" : ""
                  }`}
                  value={fields.password}
                  name="password"
                  onChange={(e) => inputHandler(e)}
                />
                <span className="red-text"> {errors.password} </span>
              </div>
              <div className="text-center mt-2">
                <MDBBtn color="secondary" type="submit">
                  {isAuthenticating ? (
                    <div
                      className="spinner-border spinner-border-sm white-text mr-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </MDBBtn>
              </div>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <div className="version-container">
        <span className="version">v{REACT_APP_VERSION}</span>
      </div>
    </MDBContainer>
  );
};

export default Login;
